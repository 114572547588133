import React, { Component } from 'react';
import { LazyImage } from "../../../book-renderer/LazyImage.jsx";
import { Targets } from "./Targets.jsx";
import GameBar from "../game-bar/GameBar.jsx";
import styles from "./BreakUp.module.scss";
import { data } from "./data/game.js";
import styles_Basic from "../../../book-renderer/ScenceBasicElement.module.scss";

const gameTime = 30;
const successPauseTime = 2;
const failurePauseTime = 2;

const maximumFreezeLevel = 3;

let targets = {};
data.targets.map((target, index) => targets[index] = {
    freezeLevel: maximumFreezeLevel
});
const initData = {
    countingTime: 0,
    targets,
    freezeAllElement: false,
}

class BreakUp extends Component {
    static displayName = 'BreakUp';
    
    constructor(props) {
        super(props);
        this.state = {
            isPausing: props.isPausing,
            countingTime: 0,
            targets,
            freezeAllElement: false,
        }
        this.mainInterval = null;
    }

    componentDidMount() {
        this.initData();
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.isPausing !== nextProps.isPausing) {
            this.setState({ isPausing: nextProps.isPausing });
        }

        if(this.props.uuid !== nextProps.uuid) {
            this.initData();
        }
    }

    initData = () => {
        this.setState((prevState) => ({ 
            ...prevState,
            ...initData 
        }), () => {
            clearInterval(this.mainInterval);
            this.mainInterval = setInterval(this.handleTimer, 1000);
        });
    }

    handleTimer = () => {
        if(!this.state.isPausing) {
            this.setState({ countingTime: this.state.countingTime+1 }, () => {
                const currentTime = this.state.countingTime;
                if(gameTime - currentTime === 0) {
                    this.setState({ freezeAllElement: true });
                    clearInterval(this.mainInterval);
                    setTimeout(() => {
                        this.props.loseGame();
                    }, failurePauseTime*1000);
                }
            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.mainInterval);
    }

    setFreezeLevel = (key, rawLevel) => {
        if(rawLevel<0) rawLevel=0;

        this.setState((prevState) => ({
            ...prevState,
            targets: {
                ...prevState.targets,
                [key]: {
                    ...prevState.targets[key],
                    freezeLevel: rawLevel
                }
            }
        }), () => {
            const restTargets = Object.keys(this.state.targets).filter(key => (this.state.targets[key].freezeLevel>0));
            if(restTargets.length===0) {
                this.setState({ freezeAllElement: true });
                clearInterval(this.mainInterval);
                setTimeout(() => {
                    this.props.winGame(0);
                }, successPauseTime*1000);
            }
        });
    }

    render() {
        return (
            <div className={
                `${styles_Basic.BackgroundWrapper}`
                + ` ${this.state.freezeAllElement ? styles.Freeze : '' }`
            }>
                <img className={styles_Basic.BackgroundImg} src={data.backgroundUrl} />

                {data.assets.map((asset, index) => 
                    <div 
                        key={index}
                        className={styles.AssetWrapper}
                        style={{
                            width: asset.width,
                            left: asset.x,
                            top: asset.y,
                        }}
                    >
                        <LazyImage
                            loadLazyImage={this.props.loadLazyImage}
                            // ref={ref => assetsRef[index] = ref}
                            src={asset.url}
                            attributes={{
                                draggable: false,
                                alt: ""
                            }}
                        />
                    </div>
                    
                )}

                <GameBar 
                    colorType="Orange"
                    gameTime={gameTime}
                    countingTime={this.state.countingTime}
                    isPausing={this.state.isPausing}
                    isFreezingAllElement={this.state.freezeAllElement}
                    resumeGame={this.props.resumeGame}
                    pauseGame={this.props.pauseGame}
                    loadLazyImage={this.props.loadLazyImage}
                />

                <Targets 
                    loadLazyImage={this.props.loadLazyImage}
                    targetsData={data.targets}
                    targetsFreezeData={{...this.state.targets}}
                    setFreezeLevel={this.setFreezeLevel}
                    freezeAllElement={this.state.freezeAllElement}
                />
            </div>
        );
    }
}

export default BreakUp;