import Counting from "./Counting.jsx";

import { data as coverData } from "./data/cover.js";
import { data as modalData } from "./data/modal.js";

export const data = {
    name: "逃出黑洞",
    component: Counting,
    ruleDescription: "數字黑洞裡，\n順著星星身上的數字，\n依序點擊就可以逃出這黑洞",
    modal: modalData,
    cover: coverData
}