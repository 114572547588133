import React from 'react';
import ReactModal from 'react-modal';
import { LazyImage } from "./../../LazyImage.jsx";
import { CrossSvg } from './../assets/CrossSvg.jsx';
import "./CustomModal.scss";
import SoundButton from "./../../sound-button/SoundButton.jsx";

import styles from "./Modal.module.scss";
import styles_Basic from "./../../ScenceBasicElement.module.scss";

const Mode = {
    HorizontalMode: "HorizontalMode",
    VerticalMode: "VerticalMode",
    MobileMode: "MobileMode"
}

export const Modal = props => 
    <>
        <ReactModal
            // id="test1"
            isOpen={true}
            closeTimeoutMS={300}
            style={{
                ...customStyles,
                overlay: {
                    ...customStyles.overlay,
                    zIndex: props.isOpen ? '1' : '-1',
                    opacity: props.isOpen ? '1' : '0',
                    transition: props.isOpen ? 
                        'opacity 0.3s ease-in-out' : 
                        'z-index 0.3s step-end, opacity 0.3s ease-in-out',
                },
                content: {
                    ...customStyles.content,
                    width: props.interfaceMode===Mode.HorizontalMode ? `calc(133.3333vh - 26.6vh)` : props.interfaceMode===Mode.MobileMode ? 'calc(100vw - 16px)' : 'calc(100vw - 20%)',
                    // left: props.interfaceMode===Mode.HorizontalMode ? `calc(calc(calc(100vw - 133.3333vh)/2) + 13.3vh)` : props.interfaceMode===Mode.MobileMode ? '8px' : '10%',
                    // right: props.interfaceMode===Mode.HorizontalMode ? `calc(calc(calc(100vw - 133.3333vh)/2) + 13.3vh)` : props.interfaceMode===Mode.MobileMode ? '8px' : '10%',
                    top: props.interfaceMode===Mode.VerticalMode ? `calc(calc(calc(100vh - 75vw)/2) + 7.5vw)` : props.interfaceMode===Mode.MobileMode ? '50%' : '10%',
                    bottom: props.interfaceMode===Mode.VerticalMode ? `calc(calc(calc(100vh - 75vw)/2) + 7.5vw)` : props.interfaceMode===Mode.MobileMode ? 'initial' : '10%',
                    maxWidth: props.interfaceMode===Mode.MobileMode ? '400px' : 'initial',
                    maxHeight: props.interfaceMode===Mode.MobileMode ? 'calc(100% - 16px)' : 'initial',
                    borderRadius: props.interfaceMode===Mode.MobileMode ? '8px' : '32px',
                    // transform: props.interfaceMode===Mode.MobileMode ? 'translate(0, -50%)' : 'initial'
                    transform: props.interfaceMode===Mode.MobileMode ? 'translate(-50%, -50%)' : 'translate(-50%, 0)',
                }
            }}
            parentSelector={() => document.body}
            ariaHideApp={false}
        >
            <div className={styles.Page}>
                <LazyImage
                    loadLazyImage={props.loadLazyImage}
                    src={props.interfaceMode!==Mode.MobileMode ? props.content.url : props.content.urlMB}
                    attributes={{
                        draggable: false,
                        alt: ""
                    }}
                />
                {(props.content.buttons && props.content.buttons.map((button, jdex) =>
                    <SoundButton
                        key={jdex}
                        className={`${styles_Basic.AssetWrapper} ${styles_Basic.Button}`}
                        style={{
                            width: button.width,
                            left: button.x,
                            top: button.y,
                            display: props.interfaceMode===Mode.MobileMode ?
                                (button.hideOnMobile ? 'none' : 'block') :
                                (button.hideOnDesktop ? 'none' : 'block'),
                        }}
                        onClick={() => window.open(button.linkUrl, '_blank')} 
                    >
                        <LazyImage
                            loadLazyImage={props.loadLazyImage}
                            src={button.url}
                            attributes={{
                                draggable: false,
                                alt: ""
                            }}
                        />
                    </SoundButton>
                ))}
            </div>
        </ReactModal>
        <ReactModal
            isOpen={true}
            closeTimeoutMS={300}
            style={{
                ...buttonsModalStyles,
                overlay: {
                    ...buttonsModalStyles.overlay,
                    zIndex: props.isOpen ? '1' : '-1',
                    opacity: props.isOpen ? '1' : '0',
                    transition: props.isOpen ? 
                        'opacity 0.3s ease-in-out' : 
                        'z-index 0.3s step-end, opacity 0.3s ease-in-out',
                },
                content: {
                    ...buttonsModalStyles.content,
                    width: props.interfaceMode===Mode.HorizontalMode ? `calc(133.3333vh - 26.6vh)` : props.interfaceMode===Mode.MobileMode ? 'calc(100vw - 16px)' : 'calc(100vw - 20%)',
                    // left: props.interfaceMode===Mode.HorizontalMode ? `calc(calc(calc(100vw - 133.3333vh)/2) + 13.3vh)` : props.interfaceMode===Mode.MobileMode ? '8px' : '10%',
                    // right: props.interfaceMode===Mode.HorizontalMode ? `calc(calc(calc(100vw - 133.3333vh)/2) + 13.3vh)` : props.interfaceMode===Mode.MobileMode ? '8px' : '10%',
                    top: props.interfaceMode===Mode.VerticalMode ? `calc(calc(calc(100vh - 75vw)/2) + 7.5vw)` : props.interfaceMode===Mode.MobileMode ? '50%' : '10%',
                    bottom: props.interfaceMode===Mode.VerticalMode ? `calc(calc(calc(100vh - 75vw)/2) + 7.5vw)` : props.interfaceMode===Mode.MobileMode ? 'initial' : '10%',
                    maxWidth: props.interfaceMode===Mode.MobileMode ? '400px' : 'initial',
                    maxHeight: props.interfaceMode===Mode.MobileMode ? 'calc(100% - 16px)' : 'initial',
                    borderRadius: props.interfaceMode===Mode.MobileMode ? '8px' : '32px',
                    // transform: props.interfaceMode===Mode.MobileMode ? 'translate(0, -50%)' : 'initial'
                    transform: props.interfaceMode===Mode.MobileMode ? 'translate(-50%, -50%)' : 'translate(-50%, 0)',
                }
            }}
            parentSelector={() => document.body}
            ariaHideApp={false}
        >
            <div style={{ height: '100%' }}>
                <SoundButton 
                    className={styles.CloseButton} 
                    style={{ opacity: 1 }}
                    onClick={() => props.closeModal() }
                >
                    <CrossSvg />
                </SoundButton>
                <div className={`${styles.Page}`}>
                    <LazyImage
                        loadLazyImage={props.loadLazyImage}
                        src={props.interfaceMode!==Mode.MobileMode ? props.content.url : props.content.urlMB}
                        attributes={{
                            draggable: false,
                            style: { opacity: 0 },
                            alt: ""
                        }}
                    />
                </div>
            </div>
        </ReactModal>
    </>

const customStyles = {
    overlay: {
        position: "absolute",
        // backgroundColor: 'rgba(0, 0, 0, 0)',
        backgroundColor: 'rgba(4, 12, 64, 0.85)',
    },
    content: {
        top: '10%',
        left: '50%',
        // right: '10%',
        bottom: '10%',
        padding: '0',
        margin: 'auto',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        border: 'none',
        borderRadius: '32px',
        boxShadow: 'rgba(255, 255, 255, 0.35) 0px 0px 50px 12px',
        transition: 'opacity 0.3s ease-in-out',
    }
};

const buttonsModalStyles = {
    overlay: {
        position: "absolute",
        backgroundColor: 'rgba(0, 0, 0, 0)',
        pointerEvents: 'none',
    },
    content: {
        top: '10%',
        left: '50%',
        // right: '10%',
        bottom: '10%',
        margin: 'auto',
        padding: '0',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        border: 'none',
        borderRadius: '0',
        pointerEvents: 'none',
        // boxShadow: 'rgba(255, 255, 255, 0.35) 0px 0px 50px 12px',
    }
};