import { useState } from "react";
import { useInterval } from "./useInterval.jsx";
import useSound from 'use-sound';

export const useSmoothBGM = (soundUrl) => {
    const [isRunning, setIsRunning] = useState(false);
    const [volume, setVolume] = useState(0);
    const [volumeDirection, setVolumeDirection] = useState(1);
    const [play, { stop }] = useSound(soundUrl, {
        volume,
        // autoplay,
        loop: true,
        // interrupt: true,
    });

    const totalTime = 600;
    const offsetVolume = 0.05;
    useInterval(() => {
        setVolume(volume + volumeDirection*offsetVolume);
        if(volumeDirection===1 && Math.ceil(volume*1000)>=1000) setIsRunning(false);
        if(volumeDirection===-1 && Math.floor(volume*1000)<=0) {
            setIsRunning(false);
            stop();
        }
    }, isRunning ? totalTime*offsetVolume : null);

    const playMusic = () => {
        play();
        setIsRunning(true);
        setVolumeDirection(1);
    }

    const stopMusic = () => {
        setIsRunning(true);
        setVolumeDirection(-1);
    }
    return [playMusic, stopMusic];
    // const savedCallback = useRef();
  
    // useEffect(() => {
    //   savedCallback.current = callback;
    // });
  
    // useEffect(() => {
    //   function tick() {
    //     savedCallback.current();
    //   }
  
    //   let id = null;
    //   if(delay) id = setInterval(tick, delay);
    //   return () => id && clearInterval(id);
    // }, [delay]);
}