import React from "react";
import { LazyImage } from "../../../book-renderer/LazyImage.jsx";
import styles from "./Targets.module.scss";

import useSound from "use-sound";
import clickPieceMp3 from "./assets/game/click-piece.mp3";
import switchPieceMp3 from "./assets/game/switch-piece.mp3";

export const Targets = props => {
    const [playClickPiece] = useSound(clickPieceMp3);
    const [playSwitchPiece] = useSound(switchPieceMp3);

    return Object.keys(props.targetsSeatData).map((key, index) => {
        return (
            <div
                className={styles.Target}
                style={{
                    left: (props.targetsSeatData[key].seatIndex % 3) * (100/3) + '%',
                    top: Math.floor(props.targetsSeatData[key].seatIndex / 3) * (100/3) + '%',
                }}
                key={index}
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    if(props.holdingTargetKey === null || parseInt(props.holdingTargetKey)===parseInt(key)) {
                        playClickPiece();
                        props.toggleTarget(key);
                    } else {
                        playSwitchPiece();
                        props.switchTarget(key);
                    }
                }}
            >
                <LazyImage
                    loadLazyImage={props.loadLazyImage}
                    src={props.targetsData[key].url}
                    attributes={{
                        className: props.holdingTargetKey===key ? styles.Holding : '',
                        draggable: false,
                        alt: ""
                    }}
                />
            </div>
        )
    })
}