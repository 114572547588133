import React, { useState, useEffect, useMemo } from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import ReactFullpage from '@fullpage/react-fullpage';
import Navigator from "./navigator/Navigator.jsx";
import Footer from "./template/footers/Footer.jsx";
import Page from "book-renderer/Page.jsx";
import { pages } from "data/pages.js";
import { useWindowSize } from "book-renderer/hook/useWindowSize.jsx";
import FullscreenButton from "./FullscreenButton.jsx";
import "./Fullpage.scss";

import useSound from 'use-sound';
import { useSmoothBGM } from "book-renderer/hook/useSmoothBGM.jsx";
import StoryBGMSoundUrl from "book-renderer/sounds/story_BGM.mp3";
import GameBGMSoundUrl from "book-renderer/sounds/game_BGM_v2.mp3";
import gameSuccessMp3 from "./../data/assets/game-success.mp3";
import gameFailureMp3 from "./../data/assets/game-failure.mp3";
import clickButtonSound from "book-renderer/sounds/click.mp3";
import ClickSoundContext from './sound-button/context.js';

const NavigatorId = "navigator";
const FooterId = "footer";
const FullscreenId = "fullscreen"
const gamesFullpageIndex = [5 ,8, 11];

const Mode = {
    HorizontalMode: "HorizontalMode",
    VerticalMode: "VerticalMode",
    MobileMode: "MobileMode"
}

const pageNames = pages.map((page) => page.name);

const getMode = (width, height) => {
    if(width < 600) return (height/width <= 0.75) ? Mode.HorizontalMode : Mode.MobileMode;
    else return (height/width > 0.75) ? Mode.VerticalMode : Mode.HorizontalMode;
}

const targetElement = document.querySelector('.ReactModal__Content');
const disableScroll = () => disableBodyScroll(targetElement);
const enableScroll = () => enableBodyScroll(targetElement);

const StoryBook = () => {
    const [storybookLoaded, setStorybookLoaded] = useState(false);
    const [storybookOpened, setStorybookOpened] = useState(false);
    const [screenWidth, screenHeight] = useWindowSize();
    const [mode, setMode] = useState(getMode(screenWidth, screenHeight));
    const [sectionSwitcherLocked, setSectionSwitcherLocked] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [pagesVisitedState, setPagesVisitedState] = useState([true]);
    const [gamesState, setGamesState] = useState([false, false, false]);
    const [marketingsState, setMarketingsState] = useState([false, false, false, false]);
    const [isFullscreen, setIsFullscreen] = useState(false);
    
    const [playStoryBGM, stopStoryBGM] = useSmoothBGM(StoryBGMSoundUrl);
    const [playGameBGM, stopGameBGM] = useSmoothBGM(GameBGMSoundUrl);
    const [playGameSuccess] = useSound(gameSuccessMp3);
    const [playGameFailure] = useSound(gameFailureMp3);
    const [playClickSound] = useSound(clickButtonSound);

    const handleSectionSwitcherLocked = (newStatus=false) => {
        if(newStatus !== sectionSwitcherLocked) {
            setSectionSwitcherLocked(newStatus);
            if(newStatus) disableScroll();
            else enableScroll();
        }
    }

    useEffect(() => {
        const currnetMode = getMode(screenWidth, screenHeight);
        if(currnetMode !== mode) setMode(currnetMode);
    }, [screenWidth, screenHeight, isFullscreen]);

    return useMemo(() => {
    return (
        <div className={mode}>
            <ReactFullpage
                licenseKey = {'8DD4F256-07404E01-91871EC1-4D660A91'}
                lockAnchors={true}
                scrollingSpeed={400}
                touchSensitivity={25}
                anchors={pageNames} 
                navigation={false}
                fixedElements={`#${NavigatorId}, #${FooterId}, #${FullscreenId}`}
                scrollOverflow={true}
                // autoScrolling={false}
                // normalScrollElements={'#test1, #test2'}
                normalScrollElements={'.ReactModal__Content'}
                onLeave={(origin, destination, direction) => { 
                    if(!sectionSwitcherLocked) {
                        if(origin.index===0 && destination.index===1 && !storybookOpened) {
                            setStorybookOpened(true);
                            setTimeout(() => playStoryBGM(), 300);
                        }
                        setActiveIndex(destination.index);
                        setPagesVisitedState(prevState => {
                            prevState[destination.index] = true;
                            return prevState;
                        });
                        window.fullpage_api.setMouseWheelScrolling(false);
                        window.fullpage_api.setKeyboardScrolling(false);
                    }
                    return !sectionSwitcherLocked;
                }}
                // afterResize={function(screenWidth, screenHeight) { }}
                // afterResponsive={function(isResponsive) { }}
                afterLoad={(origin, destination, direction) => { 
                    setTimeout(() => {
                        window.fullpage_api.setMouseWheelScrolling(true);
                        window.fullpage_api.setKeyboardScrolling(true);
                    }, 1200);
                }}
                afterRender={() => { 
                    setStorybookLoaded(true);
                    // playStoryBGM();
                    // setTimeout(() => playStoryBGM(), 500);
                }}
                render={({ state, fullpageApi }) => {
                    // console.log('fullpage render');
                    // TODO: Why it render twice? Can we avoid this happend?
                    if(!state || !fullpageApi) return <ReactFullpage.Wrapper><div className="section" /></ReactFullpage.Wrapper>;
                    return (
                        <ReactFullpage.Wrapper>
                            <ClickSoundContext.Provider value={{ playClickSound }}>
                                {pages.map((page, index) => 
                                    <Page key={index} mode={mode} backgroundColor={page.backgroundColor}>
                                        {React.createElement(page.template, {
                                            pageName: page.name,
                                            // loadLazyImage: (
                                            //     (index <= activeIndex+4)
                                            //     && (index >= activeIndex)
                                            // ),
                                            loadLazyImage: true,
                                            isActive: storybookLoaded && activeIndex===index,

                                            // index,
                                            interfaceMode: mode,
                                            data: page.data,

                                            handleSectionSwitcherLocked: handleSectionSwitcherLocked,

                                            gamesState,
                                            setGamesState,
                                            marketingsState,
                                            setMarketingsState,

                                            playStoryBGM,
                                            stopStoryBGM,
                                            playGameBGM, 
                                            stopGameBGM,
                                            playGameSuccess,
                                            playGameFailure
                                        })}
                                    </Page>
                                )}
                                <Navigator
                                    id={NavigatorId}
                                    items={pages.map((page, index) => ({
                                        type: page.type,
                                        name: page.name,
                                    }))}
                                    activeIndex={activeIndex}
                                    fullpageApi={fullpageApi}
                                    isHidden={sectionSwitcherLocked}
                                    mode={mode}
                                    gamesFullpageIndex={gamesFullpageIndex}
                                    gamesState={gamesState}
                                    pagesVisitedState={pagesVisitedState}
                                />
                                <Footer
                                    isActive={activeIndex===pages.length-1}
                                    id={FooterId}
                                    mode={mode}
                                />
                                <FullscreenButton 
                                    id={FullscreenId}
                                    isHorizontal={screenWidth/screenHeight>1}
                                    isFullscreen={isFullscreen}
                                    setIsFullscreen={setIsFullscreen}
                                />
                            </ClickSoundContext.Provider>
                        </ReactFullpage.Wrapper>
                    );
                }}
            />
        </div>
    )
    }, [storybookLoaded, mode, sectionSwitcherLocked, activeIndex, pagesVisitedState, gamesState, marketingsState, isFullscreen])
}

export default StoryBook;