import React from 'react';
import { LazyImage } from "./../../../LazyImage";
import SoundButton from "./../../../sound-button/SoundButton.jsx";
import styles from "./Modal.module.scss";
import styles_Basic from "./../../../ScenceBasicElement.module.scss";

export const Modal = props => {
    return (
        <div className={
            `${styles_Basic.BackgroundWrapper}`
            + ` ${props.data.backgroundUrl ? styles.ImageWrapper : styles.ShadowWrapper}` 
            + (props.isOpen ? ` ${styles.Active}` : '')} 
        >
            {props.data.backgroundUrl ? 
                <LazyImage
                    loadLazyImage={props.loadLazyImage}
                    src={props.data.backgroundUrl}
                    attributes={{
                        className: styles_Basic.BackgroundImg,
                        draggable: false,
                        alt: ""
                    }}
                /> : 
                <div className={styles.Shadow} />
            }
            {props.data.assets.map((asset, index) => 
                <div 
                    key={index}
                    className={styles_Basic.AssetWrapper}
                    style={{
                        width: asset.width,
                        left: asset.x,
                        top: asset.y,
                    }}
                >
                    <LazyImage
                        loadLazyImage={props.loadLazyImage}
                        src={asset.url}
                        attributes={{
                            className: asset.animation,
                            draggable: false,
                            alt: ""
                        }}
                    />
                </div>
            )}
            {props.data.buttons.map((button, index) =>
                <SoundButton
                    key={index} 
                    className={`${styles_Basic.AssetWrapper} ${styles_Basic.Button}`}
                    style={{
                        width: button.width,
                        left: button.x,
                        top: button.y,
                    }}
                    onClick={button.clickEvent}
                >
                    <LazyImage
                        loadLazyImage={props.loadLazyImage}
                        src={button.url}
                        attributes={{
                            draggable: false,
                            alt: ""
                        }}
                    />
                </SoundButton>
            )}
        </div>
    )
}