import BG from "./assets/BG.jpg";
// import BG from "./assets/tmp.jpg";

import Ice from "./assets/ice.png";
import LittleIce from "./assets/little-ice.png";
import IceGrape from "./assets/ice-grape.png";
import IcePineapple from "./assets/ice-pineapple.png";
import Bomb from "./assets/bomb.png";
import Hand from "./assets/hand.png";
import Text from "./assets/text.png";

import IceTomato from "./assets/ice-tomato.png";
import IceTomatoGlowing from "./assets/ice-tomato-glowing.png";
import IceTomatoSparkling from "./assets/ice-tomato-sparkling.png";
import { data as marketingData } from "./../../marketing/index.js";

import Wipes from "./../../assets/wipes.png";
import animations from "./../../assets/Animation.module.scss";

export const data = {
    barComponent: null,
    mainText: '飛飛最常做的事，\n就是把被宇宙寒冰凍結的食物，\n喀拉喀垃地敲開來。',
    mainTextDelay: 2800,
    backgroundUrl: BG,
    animationAcitvator: animations.Active,
    assets: [
        {
            url: Ice,
            width: '99%',
            x: '49.4%',
            y: '50%',
        },
        {
            url: LittleIce,
            width: '11.3%',
            x: '46.6%',
            y: '86.3%',
        },
        {
            url: IceGrape,
            width: '28.1%',
            x: '82.4%',
            y: '43.2%',
            animeProperties: {
                rotate: ['-1deg', 0],
                translateY: ['2%', 0],
                translateX: ['-4%', 0],
                delay: 1550,
                duration: 300,
                easing: 'cubicBezier(.17,.67,.66,1.31)',
                translateZ: 0,
            },
        },
        {
            url: IcePineapple,
            width: '40.5%',
            x: '53.5%',
            y: '32.2%',
            animeProperties: {
                translateY: [
                    { value: [0, '-2%'], delay: 1300, duration: 400 },
                    { value: ['-2%', 0], duration: 400 },
                ],
                translateX: [
                    { value: ['-5%', '-2%'], delay: 1300, duration: 400 },
                    { value: ['-2%', 0], duration: 400 },
                ],
                easing: 'cubicBezier(.17,.67,.66,1.31)',
                translateZ: 0,
            },
        },
        {
            url: Bomb,
            width: '50%',
            x: '44.4%',
            y: '55.4%',
            animeProperties: {
                scale: [0.6, 1],
                opacity: [0, 1],
                delay: 1300,
                duration: 1000,
                easing: 'cubicBezier(.17,.67,.66,1.31)'
            },
        },
        {
            url: Hand,
            width: '50.2%',
            x: '25%',
            y: '68.3%',
            animeProperties: {
                translateX: ['-120%', 0],
                translateY: ['60%', 0],
                scale: [1.3, 1],
                delay: 300,
                duration: 2000,
                easing: 'easeInOutExpo',
                translateZ: 0,
            },
        },
        {
            url: Text,
            width: '30.7%',
            x: '61.2%',
            y: '45.2%',
            zIndex: '3',
            hideOnMobile: true,
            animeProperties: {
                opacity: [0, 1],
                delay: 2800,
                duration: 800,
                easing: 'easeInOutSine'
            },
        },
    ],
    marketings: [
        {
            id: 0,
            url: IceTomato,
            width: '15.2%',
            x: '66%',
            y: '72%',
            content: marketingData[0],
            attaches: [
                {
                    url: IceTomatoGlowing,
                    width: '128%',
                    x: '57%',
                    y: '50.5%',
                    zIndex: -2,
                    animation: animations.Glowing,
                },
                {
                    url: IceTomatoSparkling,
                    width: '137%',
                    x: '51%',
                    y: '50.3%',
                    zIndex: -1,
                    animation: animations.Sparkling,
                },
                {
                    url: Wipes,
                    width: '140%',
                    x: '57%',
                    y: '53%',
                    animation: animations.Wipping,
                }
            ]
        },
    ]
};