import BG from "./../assets/game/BG.jpg";
// import BG from "./../assets/game/tmp.jpg";
// import BG from "./../assets/modal/wrapper-tmp.jpg";
// import BG from "./../assets/modal/wrapper-start-tmp.jpg";
// import BG from "./../assets/modal/wrapper-tmp-1.jpg";
// import BG from "./../assets/modal/wrapper-tmp-2.jpg";

import Number_1 from "./../assets/game/number-1.png";
import Number_2 from "./../assets/game/number-2.png";
import Number_3 from "./../assets/game/number-3.png";
import Number_4 from "./../assets/game/number-4.png";
import Number_5 from "./../assets/game/number-5.png";
import Number_6 from "./../assets/game/number-6.png";
import Number_7 from "./../assets/game/number-7.png";
import Number_8 from "./../assets/game/number-8.png";
import Number_9 from "./../assets/game/number-9.png";
import Number_10 from "./../assets/game/number-10.png";
import Number_11 from "./../assets/game/number-11.png";
import Number_12 from "./../assets/game/number-12.png";
import Number_13 from "./../assets/game/number-13.png";
import Number_14 from "./../assets/game/number-14.png";
import Number_15 from "./../assets/game/number-15.png";

import animations from "./../Animation.module.scss";

export const data = {
    backgroundUrl: BG,
    targets: [
        {
            url: Number_1,
            width: '7.2%',
            x: '30.7%',
            y: '38.9%',
            animation: animations.SwagingReverse,
        },
        {
            url: Number_2,
            width: '5.8%',
            x: '70.05%',
            y: '35.5%',
            animation: animations.Bombing,
        },
        {
            url: Number_3,
            width: '8.1%',
            x: '51.4%',
            y: '53.3%',
            animation: animations.SwagingReverse,
        },
        {
            url: Number_4,
            width: '6.8%',
            x: '41.4%',
            y: '35.9%',
            animation: animations.Bombing,
        },
        {
            url: Number_5,
            width: '6.8%',
            x: '69.9%',
            y: '54%',
            animation: animations.SwagingMove,
        },
        {
            url: Number_6,
            width: '7.5%',
            x: '29.5%',
            y: '49.8%',
            animation: animations.SwagingReverse,
        },
        {
            url: Number_7,
            width: '5.9%',
            x: '58.7%',
            y: '33.8%',
            animation: animations.Bombing,
        },
        {
            url: Number_8,
            width: '7%',
            x: '42.2%',
            y: '69.4%',
            animation: animations.SwagingReverse,
        },
        {
            url: Number_9,
            width: '7.1%',
            x: '62.05%',
            y: '44.4%',
            animation: animations.SwagingReverse,
        },
        {
            url: Number_10,
            width: '7.2%',
            x: '33.7%',
            y: '69.9%',
            animation: animations.Swaging,
        },
        {
            url: Number_11,
            width: '5.9%',
            x: '70.3%',
            y: '71.7%',
            animation: animations.Swaging,
        },
        {
            url: Number_12,
            width: '8.5%',
            x: '52.9%',
            y: '71.4%',
            animation: animations.Bombing,
        },
        {
            url: Number_13,
            width: '5.9%',
            x: '48.9%',
            y: '40.3%',
            animation: animations.Swaging,
        },
        {
            url: Number_14,
            width: '6.8%',
            x: '60%',
            y: '58.6%',
            animation: animations.Bombing,
        },
        {
            url: Number_15,
            width: '8.4%',
            x: '40%',
            y: '52.7%',
            animation: animations.SwagingReverse,
        },
    ],
    assets: [

    ]
};