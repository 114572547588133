import { useRef, useEffect } from "react";

export const useInterval = (callback, delay) => {
    const savedCallback = useRef();
  
    useEffect(() => {
        savedCallback.current = callback;
    });
  
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
  
        let id = null;
        if(delay) id = setInterval(tick, delay);
        return () => id && clearInterval(id);
    }, [delay]);
}