import React, { useState } from "react";
import { Modal } from "./modal/Modal.jsx";
import ButtonDonate from "./assets/button-donate.png";
import ButtonDonateGlowing from "./assets/button-donate-glowing.png";
import ButtonHome from "./assets/button-home.png";
import ButtonHomeGlowing from "./assets/button-home-glowing.png";
import ButtonFB from "./assets/button-fb.png";
import ButtonFBGlowing from "./assets/button-fb-glowing.png";
import ButtonAbout from "./assets/button-about.png";
import ButtonAboutGlowing from "./assets/button-about-glowing.png";
import ButtonShare from "./assets/button-share.png";
import ButtonShareGlowing from "./assets/button-share-glowing.png";
import SoundButton from "../../../../sound-button/SoundButton.jsx";

import styles from "./Header.module.scss";
import styles_Basic from "./../../../../ScenceBasicElement.module.scss";

const Header = props => {
    const [modalOpened, setModalOpened] = useState(false);

    const openModal = () => {
        props.handleSectionSwitcherLocked(true);
        setModalOpened(true);
    }

    const closeModal = () => {
        props.handleSectionSwitcherLocked(false);
        setModalOpened(false);
    }

    return (
        <>
            <div className={styles.Wrapper}>
                <SoundButton
                    className={styles.Item} 
                    onClick={() => window.open('https://bit.ly/2VhkdE0', '_blank')} 
                >
                    <>
                        <img 
                            src={ButtonDonate} 
                            // style={{ height: "70%" }} 
                        />
                        <img 
                            className={styles.Glowing} 
                            src={ButtonDonateGlowing} 
                            // style={{ left: '-14%' }} 
                        />
                        <div className={styles.TipWrapper}><p>響應捐款</p></div>
                    </>
                </SoundButton>
                <SoundButton
                    className={styles.Item} 
                    onClick={() => window.open('https://www.eden.org.tw/', '_blank')} 
                >
                    <>
                        <img 
                            src={ButtonHome} 
                            // style={{ height: "70%" }} 
                        />
                        <img 
                            className={styles.Glowing} 
                            src={ButtonHomeGlowing} 
                            // style={{ top: '3%', left: '-18%' }}
                        />
                        <div className={styles.TipWrapper}><p>伊甸官網</p></div>
                    </>
                </SoundButton>
                <SoundButton
                    className={styles.Item} 
                    onClick={() => window.open('https://www.facebook.com/edenfan/', '_blank')} 
                >
                    <>
                        <img 
                            src={ButtonFB} 
                            // style={{ height: "70%" }} 
                        />
                        <img 
                            className={styles.Glowing} 
                            src={ButtonFBGlowing} 
                        />
                        <div className={styles.TipWrapper}><p>伊甸 FB</p></div>
                    </>
                </SoundButton>
                <SoundButton
                    className={styles.Item} 
                    onClick={() => window.open(`https://www.facebook.com/dialog/feed?app_id=1514064148774319&display=popup&link=https://lovekidstory.eden.org.tw/`, '', 'left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0')} 
                >
                    <>
                        <img 
                            src={ButtonShare} 
                            // style={{ height: "70%" }} 
                        />
                        <img 
                            className={styles.Glowing} 
                            src={ButtonShareGlowing} 
                        />
                        <div className={styles.TipWrapper}><p>分享此繪本</p></div>
                    </>
                </SoundButton>
            </div>
            <SoundButton
                className={`${styles_Basic.AssetWrapper} ${styles.AboutItem}`} 
                onClick={openModal} 
            >
                <>
                    <img 
                        src={ButtonAbout} 
                    />
                    <img 
                        className={styles.Glowing} 
                        src={ButtonAboutGlowing} 
                    />
                </>
            </SoundButton>
            <Modal
                isOpen={modalOpened}
                closeModal={closeModal}
                parent={props.modalParent}
                interfaceMode={props.interfaceMode}
            />
        </>
    );
}

export default Header;