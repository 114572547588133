import { useLayoutEffect, useState } from "react";

export const useWindowSize = () => {
    const [size, setSize] = useState([
        window.innerWidth || document.clientWidth || document.body.clientWidth, 
        window.innerHeight || document.clientHeight|| document.body.clientHeight
    ]);
    useLayoutEffect(() => {
        function updateSize() {
            let width = window.innerWidth || document.clientWidth || document.body.clientWidth;
            let height = window.innerHeight || document.clientHeight|| document.body.clientHeight;
            setSize([width, height]);
            let vw = width * 0.01;
            let vh = height * 0.01;
            document.documentElement.style.setProperty('--vw', `${vw}px`);
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
        window.addEventListener('resize', updateSize);
        // window.addEventListener('orientationchange', updateSize);
        updateSize();
        return () => {
            window.removeEventListener('resize', updateSize);
            // window.removeEventListener('orientationchange', updateSize);
        }
    }, []);
    return size;
}