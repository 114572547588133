import React, { useState, useEffect, useRef } from "react";
import MainText from "./../../MainText.jsx";
import { Modal } from "./modal/Modal.jsx";
import { LazyImage } from "./../../LazyImage.jsx";
import SoundButton from "./../../sound-button/SoundButton.jsx";
import styles_Interface from "../../Interface.module.scss";
import styles from "./Game.module.scss";
import styles_Cover from "./Cover.module.scss";
import styles_Basic from "./../../ScenceBasicElement.module.scss";
import anime from "animejs";

import { v4 as uuidv4 } from 'uuid';

const ModalType = {
    Landing: "Landing",
    Pausing: "Pausing",
    Failure: "Failure",
    Success: "Success",
    LandingFinished: "LandingFinished",
    PausingFinished: "PausingFinished",
};

const Mode = {
    HorizontalMode: "HorizontalMode",
    VerticalMode: "VerticalMode",
    MobileMode: "MobileMode"
};

const FunctionType = {
    CloseModal: "CloseMoal",
    RestartGame: "RestartGame",
    BackToStory: "BackToStory",
    OpenLandingFinished: "OpenLandingFinished",
    OpenPausingFinished: "OpenPausingFinished",
};

export const Game = props => {
    const [gameActive, setGameActive] = useState(false);
    const [modalOpened, setModalOpened] = useState(true);
    const [modalType, setModalType] = useState(ModalType.Landing);
    // const gameComponentRef = useRef(null);
    // const scenceRef = useRef(null);
    let assetsRef = [];
    const [uuid, setUuid] = useState(uuidv4());

    useEffect(() => {
        if(props.isActive) {
            assetsRef.map((assetRef, index) => {
                const assetData = props.data.cover.assets[index];
                if(assetData.animeProperties) {
                    assetRef.style.transform = "";
                    setTimeout(() => {
                        anime({ 
                            targets: assetRef, 
                            ...assetData.animeProperties 
                        });
                    }, 0);
                }
            });
        } else anime.remove(assetsRef)
    }, [props.isActive]);

    const activeGame = () => {
        props.stopStoryBGM();
        setTimeout(() => {
            setGameActive(true);
            props.playGameBGM();
        }, 1000);
    }

    const deactiveGame = () => {
        if(gameActive) {
            // stopMusic();
            props.stopGameBGM();
            setTimeout(() => {
                setGameActive(false);
                props.handleSectionSwitcherLocked(false);
                setUuid(uuidv4());
                setModalType(ModalType.Landing);
                props.playStoryBGM();
            }, 1000);
        }
    }

    useEffect(() => {
        if(!props.isActive) {
            if(gameActive) {
                deactiveGame();
            }
        }
    }, [props.isActive])
    
    const openModal = (modalType, gameId=0) => {
        // if(props.interfaceMode!==Mode.MobileMode) props.handleSectionSwitcherLocked(false);
        props.handleSectionSwitcherLocked(false);
        setModalType(modalType);
        setModalOpened(true);

        if(modalType===ModalType.Success) {
            let preState = [...props.gamesState];
            preState[gameId] = true;
            props.setGamesState([...preState]);
        }
    }

    const closeModal = () => {
        props.handleSectionSwitcherLocked(true);
        setModalOpened(false);
    }

    const restartGame = () => {
        setUuid(uuidv4());
        closeModal();
    }

    const backToStory = () => {
        window.fullpage_api.moveSectionDown();
    }

    const openLandingFinished = () => {
        setModalType(ModalType.LandingFinished);
    }

    const openPausingFinished = () => {
        setModalType(ModalType.PausingFinished);
    }

    Object.keys(props.data.modal).map(modalType => 
        props.data.modal[modalType].buttons.map(button => {
            switch(button.functionType) {
                case FunctionType.CloseModal:
                    button.clickEvent = closeModal;
                    break;
                case FunctionType.BackToStory:
                    button.clickEvent = backToStory;
                    break;
                case FunctionType.RestartGame:
                    button.clickEvent = restartGame;
                    break;
                case FunctionType.OpenLandingFinished:
                    button.clickEvent = openLandingFinished;
                    break;
                case FunctionType.OpenPausingFinished:
                    button.clickEvent = openPausingFinished;
                    break;
            }
        })
    )
    
    return (
        <>
            {/* Cover */}
            <div 
                className={
                    `${styles_Interface[props.interfaceMode]}`
                    + ` ${styles_Cover[props.interfaceMode]}`
                    + (gameActive ? ` ${styles_Cover.Active}` : '')
                }
            >
                <div className={styles_Basic.BackgroundWrapper}>

                    {/* Avoid LazyLoad */}
                    <img 
                        src={props.data.cover.backgroundUrl}
                        className={styles_Basic.BackgroundImg}
                        draggable={false}
                        alt=""
                    />

                    {props.data.cover.assets.map((asset, index) => 
                        <div 
                            key={index}
                            className={styles_Basic.AssetWrapper}
                            style={{
                                width: asset.width,
                                left: asset.x,
                                top: asset.y,
                                zIndex: asset.coverAll ? '3' : '',
                                pointerEvents: asset.pointerEvents || 'all',  
                                display: asset.hideOnMobile && props.interfaceMode===Mode.MobileMode ? 'none' : 'block',
                            }}
                        >
                            {/* Avoid LazyLoad */}
                            <LazyImage
                                loadLazyImage={props.loadLazyImage}
                                ref={ref => assetsRef[index] = ref}
                                src={asset.url}
                                attributes={{
                                    className: asset.animation,
                                    draggable: false,
                                    alt: ""
                                }}
                            />
                        </div>
                    )}
                    <SoundButton
                        className={`${styles_Basic.AssetWrapper} ${styles_Basic.Button}`}
                        style={{
                            width: props.data.cover.startButton.width,
                            left: props.data.cover.startButton.x,
                            top: props.data.cover.startButton.y,
                            zIndex: '2',
                        }}
                        onClick={activeGame}
                    >
                        <LazyImage
                            loadLazyImage={props.loadLazyImage}
                            src={props.data.cover.startButton.url}
                            attributes={{
                                alt: ""
                            }}
                        />
                    </SoundButton>
                </div>
            </div>
            <MainText 
                interfaceMode={props.interfaceMode} 
                text={props.data.cover.mainText} 
                isActive={props.isActive} 
                delay={props.data.cover.mainTextDelay}
            />

            <div 
                className={
                    `${styles_Interface[props.interfaceMode]}`
                    + ` ${styles.GameWrapper}`
                    + (gameActive ? ` ${styles.Active}` : '')
                }
            >
                {React.createElement(props.data.component, { 
                    uuid,
                    isPausing: modalOpened,
                    pauseGame: () => openModal(ModalType.Pausing),
                    resumeGame: () => closeModal(),
                    winGame: gameId => {
                        props.playGameSuccess();
                        openModal(ModalType.Success, gameId);
                    },
                    loseGame: () => {
                        props.playGameFailure()
                        openModal(ModalType.Failure);
                    },
                    loadLazyImage: props.loadLazyImage,
                    stopGameBGM: props.stopGameBGM
                })}
                {Object.keys(props.data.modal).map((key, index) => 
                    <Modal 
                        key={index}
                        isOpen={(modalType===key && modalOpened)}
                        data={props.data.modal[key]}
                        loadLazyImage={props.loadLazyImage}
                    />
                )}
            </div>
        </>
    )
}

Game.displayName = 'Game';

export default Game;