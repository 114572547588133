import BG from "./assets/BG.jpg";
// import BG from "./assets/tmp.jpg";

import Planet from "./assets/planet.png";
import CloudTop from "./assets/cloud-top.png";
import CloudBottom from "./assets/cloud-bottom.png";
import CloudFull from "./assets/cloud-full.png";
import Fye from "./assets/fye.png";
import Stars from "./assets/stars.png";
import Text from "./assets/text.png";

export const data = {
    barComponent: null,
    mainText: '「飛飛」擁有很大的力氣，\n戴著魚缸的他能飛越宇宙，\n卻聽不清楚外面的聲音，\n總是很害羞。',
    mainTextDelay: 2700,
    backgroundUrl: BG,
    assets: [
        {
            url: Planet,
            width: '77%',
            x: '50.9%',
            y: '78%',
            animeProperties: {
                translateY: ['80%', 0],
                delay: 500,
                duration: 2000,
                easing: 'cubicBezier(0,1,.52,1.08)',
                translateZ: 0,
            },
        },
        {
            url: Stars,
            width: '99.8%',
            x: '50%',
            y: '50%',
        },
        {
            url: Fye,
            width: '21.2%',
            x: '50.1%',
            y: '59.8%',
            animeProperties: {
                translateY: ['200%', 0],
                delay: 500,
                duration: 1800,
                easing: 'cubicBezier(0,1,.52,1.08)',
                translateZ: 0,
            },
        },
        {
            url: CloudFull,
            width: '100%',
            x: '50%',
            y: '50%',
            animeProperties: {
                opacity: [0, 1],
                scale: [1.8, 1],
                duration: 1600,
                easing: 'easeInOutSine',
                translateZ: 0,
            },
        },
        {
            url: CloudTop,
            width: '100%',
            x: '50%',
            y: '16.2%',
            animeProperties: {
                opacity: [0, 1],
                translateY: ['-100%', 0],
                duration: 2000,
                easing: 'easeInOutSine',
                translateZ: 0,
            },
        },
        {
            url: CloudBottom,
            width: '100%',
            x: '50%',
            y: '88.1%',
            animeProperties: {
                opacity: [0, 1],
                translateY: ['100%', 0],
                duration: 3000,
                easing: 'easeInOutSine',
                translateZ: 0,
            },
        },
        {
            url: Text,
            width: '39.2%',
            x: '51%',
            y: '31.3%',
            hideOnMobile: true,
            animeProperties: {
                opacity: [0, 1],
                delay: 2700,
                duration: 800,
                easing: 'easeInOutSine'
            },
        },
    ]
};