import React, { useState, useEffect, useMemo } from 'react';

export const LazyImage = React.forwardRef((props, ref) => {
    
    const [imageSrc, setImageSrc] = useState('');
    useEffect(() => {
        if(props.loadLazyImage && imageSrc==='') setImageSrc(props.src);
    }, [props.loadLazyImage]);

    useEffect(() => {
        if(imageSrc !== props.src && imageSrc!=='') setImageSrc(props.src);
    }, [props.src])

    // return useMemo(() => {
        return (
            <img
                ref={ref}
                src={imageSrc} 
                {...props.attributes}
            />
        )
    // }, [imageSrc, props.attributes.className]);
});