import React from "react";
import styles from "./Footer.module.scss";

import Bigger from "./Bigger.jsx";
import Smaller from "./Smaller.jsx";

const Mode = {
    HorizontalMode: "HorizontalMode",
    VerticalMode: "VerticalMode",
    MobileMode: "MobileMode"
};

const Footer = props => 
    <footer 
        id={props.id}
        className={`${styles[props.mode]} ${props.isActive ? styles.Active : ''}`}
    >
        {(props.mode===Mode.MobileMode) ? <Smaller /> : <Bigger />}
    </footer>

export default Footer;