import React from 'react';

export const FullscreenSvg = props => 
    <svg x="0px" y="0px" viewBox="0 0 477.867 477.867">
    <path d="M460.8,0H17.067C7.641,0,0,7.641,0,17.067V460.8c0,9.426,7.641,17.067,17.067,17.067H460.8
       c9.426,0,17.067-7.641,17.067-17.067V17.067C477.867,7.641,470.226,0,460.8,0z M216.866,285.133L216.866,285.133l-90.334,90.334
       H153.6c9.426,0,17.067,7.641,17.067,17.067S163.026,409.6,153.6,409.6H85.333c-9.426,0-17.067-7.641-17.067-17.067v-68.267
       c0-9.426,7.641-17.067,17.067-17.067s17.067,7.641,17.067,17.067v27.068l90.334-90.334c6.78-6.548,17.584-6.36,24.132,0.419
       C223.254,268.034,223.254,278.519,216.866,285.133z M216.866,216.866c-6.665,6.663-17.468,6.663-24.132,0L102.4,126.532V153.6
       c0,9.426-7.641,17.067-17.067,17.067s-17.067-7.641-17.067-17.067V85.333c0-9.426,7.641-17.067,17.067-17.067H153.6
       c9.426,0,17.067,7.641,17.067,17.067S163.026,102.4,153.6,102.4h-27.068l90.334,90.334
       C223.529,199.398,223.529,210.202,216.866,216.866z M409.6,392.533c0,9.426-7.641,17.067-17.067,17.067h-68.267
       c-9.426,0-17.067-7.641-17.067-17.067s7.641-17.067,17.067-17.067h27.068l-90.334-90.334c-6.548-6.78-6.36-17.584,0.42-24.132
       c6.614-6.387,17.099-6.387,23.712,0l90.334,90.334v-27.068c0-9.426,7.641-17.067,17.067-17.067s17.067,7.641,17.067,17.067
       V392.533z M409.6,153.6c0,9.426-7.641,17.067-17.067,17.067s-17.067-7.641-17.067-17.067v-27.068l-90.334,90.334
       c-6.78,6.548-17.584,6.36-24.132-0.419c-6.388-6.614-6.388-17.099,0-23.713l90.334-90.334h-27.068
       c-9.426,0-17.067-7.641-17.067-17.067s7.641-17.067,17.067-17.067h68.267c9.426,0,17.067,7.641,17.067,17.067V153.6z"/>
    </svg>