import BreakUp from "./BreakUp.jsx";

import { data as coverData } from "./data/cover.js";
import { data as modalData } from "./data/modal.js";

export const data = {
    name: "解凍食物",
    component: BreakUp,
    ruleDescription: "連續點擊冰凍食物，在時間內幫助飛飛把食物都敲開吧！",
    modal: modalData,
    cover: coverData
}