import BG from "./assets/BG.jpg";
// import BG from "./assets/tmp.jpg";

import PlantBlue from "./assets/planet-blue.png";
import PlantYellow from "./assets/planet-yellow.png";
import PlantRed from "./assets/planet-red.png";
import PlantGreen from "./assets/planet-green.png";
import Stars from "./assets/stars.png";
import IceAndfruit from "./assets/ice-and-fruit.png";
import AnimalFye from "./assets/animal-fye.png";
import AnimalFyeIce from "./assets/animal-fye-ice.png";
import AnimalGiraffe from "./assets/animal-giraffe.png";
import AnimalPenguin from "./assets/animal-penguin.png";
import Text from "./assets/text.png";
// import MeteorLeft from "./assets/meteor-left.png";
// import MeteorRight from "./assets/meteor-right.png";
// import MeteorCenter from "./assets/meteor-center.png";

export const data = {
    barComponent: null,
    mainText: '飛飛住在太空好久了，\n四處拯救宇宙需要幫助的動物們。',
    mainTextDelay: 2500,
    backgroundUrl: BG,
    assets: [
        {
            url: Stars,
            width: '95.1%',
            x: '47.5%',
            y: '49.2%',
        },
        {
            url: PlantBlue,
            width: '45.8%',
            x: '23%',
            y: '56.4%',
            animeProperties: {
                opacity: [0, 1],
                translateX: ['-7%', 0],
                translateY: ['4%', 0],
                scale: [1.1, 1],
                delay: 500,
                duration: 2000,
                easing: 'easeOutSine'
            },
        },
        {
            url: PlantYellow,
            width: '15%',
            x: '92.8%',
            y: '17.7%',
            // animeProperties: {
            //     opacity: [0, 1],
            //     translateX: ['-3%', 0],
            //     translateY: ['-6%', 0],
            //     delay: 1000,
            //     duration: 1500,
            //     easing: 'easeOutSine'
            // },
        },
        {
            url: PlantRed,
            width: '27%',
            x: '81.2%',
            y: '42.8%',
            animeProperties: {
                opacity: [0, 1],
                translateX: ['7%', 0],
                translateY: ['-4%', 0],
                delay: 500,
                duration: 2000,
                easing: 'easeOutSine'
            },
        },
        {
            url: PlantGreen,
            width: '67%',
            x: '66.5%',
            y: '83%',
            animeProperties: {
                opacity: [0, 1],
                translateX: ['4%', 0],
                translateY: ['4%', 0],
                duration: 2000,
                easing: 'easeOutSine'
            },
        },
        {
            url: IceAndfruit,
            width: '43%',
            x: '21.5%',
            y: '62.75%',
            // animeProperties: {
            //     scale: [0.9, 1],
            //     // opacity: [0, 1],
            //     translateX: ['4%', 0],
            //     translateY: ['-2%', 0],
            //     // delay: 500,
            //     duration: 1500,
            //     easing: 'easeOutSine'
            // },
        },
        {
            url: AnimalFyeIce,
            width: '5.5%',
            x: '57.2%',
            y: '34.4%',
            animeProperties: {
                opacity: [0, 1],
                scale: [0.5, 1],
                delay: 1900,
                duration: 300,
                easing: 'spring(1, 80, 10, 0)'
            },
        },
        {
            url: AnimalFye,
            width: '34.6%',
            x: '39.5%',
            y: '42.5%',
            animeProperties: {
                translateX: ['-100%', 0],
                translateY: ['50%', 0],
                duration: 2000,
                easing: 'easeOutSine',
                translateZ: 0,
            },
        },
        {
            url: AnimalGiraffe,
            width: '23.5%',
            x: '78.5%',
            y: '36.5%',
        },
        {
            url: AnimalPenguin,
            width: '19.9%',
            x: '69.1%',
            y: '75.2%',
        },
        {
            url: Text,
            width: '28.7%',
            x: '63.5%',
            y: '53.4%',
            hideOnMobile: true,
            animeProperties: {
                opacity: [0, 1],
                delay: 2500,
                duration: 800,
                easing: 'easeInOutSine'
            },
        },
        // {
        //     url: MeteorLeft,
        //     width: '9.9%',
        //     x: '18.3%',
        //     y: '31.8%',
        // },
        // {
        //     url: MeteorCenter,
        //     width: '18.5%',
        //     x: '47.9%',
        //     y: '73.6%',
        // },
        // {
        //     url: MeteorRight,
        //     width: '16.7%',
        //     x: '82.4%',
        //     y: '14.1%',
        // },
    ]
};