import React, { useState, useEffect } from "react";
import styles from "./Navigator.module.scss";
import { StarSvg } from "./assets/StarSvg.jsx";

import { useWindowSize } from "book-renderer/hook/useWindowSize.jsx";

const Mode = {
    HorizontalMode: "HorizontalMode",
    VerticalMode: "VerticalMode",
    MobileMode: "MobileMode"
}

const getMode = (width, height) => {
    if(width < 600 || height < 600) return (height/width <= 0.454545) ? Mode.HorizontalMode : Mode.MobileMode;
    else return (height/width > 0.454545) ? Mode.VerticalMode : Mode.HorizontalMode;
}

const Navigator = (props) => {
    const [screenWidth, screenHeight] = useWindowSize();
    const [mode, setMode] = useState(null);

    useEffect(() => {
        const currnetMode = getMode(screenWidth, screenHeight);
        if(currnetMode !== mode) { 
            setMode(currnetMode);
        }
    }, [screenWidth, screenHeight]);

    const handleDotOnClick = (e, index, pageName) => {
        e.preventDefault();
        if(props.pagesVisitedState[index] || props.pagesVisitedState[index-1]) {
            props.fullpageApi.moveTo(pageName)
        }
    }

    return (
        <div 
            className={`${styles[mode]} ${props.isHidden ? styles.HiddenActive : ''}`}
            id={props.id}
        >
            <ul>
                {props.items.map((page, index) => {
                    const indexOfGames = props.gamesFullpageIndex.indexOf(index+1) 
                    return (
                        (indexOfGames !== -1) ?
                            <li 
                                key={index} 
                                className={
                                    `${styles.Item}`
                                    + ` ${(index===props.activeIndex) ? styles.Active : ''}`
                                    + ` ${styles.Game}`
                                    + ` ${props.gamesState[indexOfGames] ? styles.GameCompleted : ''}`
                                    + ` ${props.pagesVisitedState[index] ? styles.Clickable : ''}`
                                } 
                                onClick={e => handleDotOnClick(e, index, page.name)}
                            >
                                <div className={styles.StarWrapper}>
                                    <StarSvg />
                                </div>
                            </li> :
                            <li 
                                key={index} 
                                className={
                                    `${styles.Item}` 
                                    + ` ${(index===props.activeIndex) ? styles.Active : ''}`
                                    + ` ${props.pagesVisitedState[index] ? styles.Clickable : ''}`
                                } 
                                onClick={e => handleDotOnClick(e, index, page.name)}
                            >
                                <div className={styles.Point} />
                            </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default Navigator;