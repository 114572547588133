import React, { Component } from 'react';
import { LazyImage } from "../../../book-renderer/LazyImage.jsx";
import { Targets } from "./Targets.jsx";
import { SuccessAnimation } from "./SuccessAnimation.jsx";
import styles from "./Puzzling.module.scss";
import GameBar from "./../game-bar/GameBar.jsx";
import Frame from "./assets/game/frame.png";
import { data } from "./data/game.js";
import styles_Basic from "../../../book-renderer/ScenceBasicElement.module.scss";

const gameTime = 30;
const successPauseTime = 9;
// const successPauseTime = 20;
const failurePauseTime = 2;

let targets = {};
data.targets.map((target, index) => {
    targets[index] = {
        seatIndex: target.initPosition,
        done: false
    };
});

const initData = {
    countingTime: 0,
    targets,
    holdingTargetKey: null,
    freezeAllElement: false,
    finishedActive: false,
}

class Puzzling extends Component {
    static displayName = 'Puzzling';
    
    constructor(props) {
        super(props);
        this.state = {
            isPausing: props.isPausing,
            countingTime: 0,
            targets: {},
            holdingTargetKey: null,
            freezeAllElement: false,
            finishedActive: false,
        }
        this.mainInterval = null;
    }

    componentDidMount() {
        this.initData();
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.isPausing !== nextProps.isPausing) {
            this.setState({ isPausing: nextProps.isPausing });
        }

        if(this.props.uuid !== nextProps.uuid) {
            this.initData();
        }
    }

    initData = () => {
        this.setState((prevState) => ({ 
            ...prevState,
            ...initData 
        }), () => {
            clearInterval(this.mainInterval);
            this.mainInterval = setInterval(this.handleTimer, 1000);
        });
    }

    handleTimer = () => {
        if(!this.state.isPausing) {
            this.setState({ countingTime: this.state.countingTime+1 }, () => {
                const currentTime = this.state.countingTime;
                if(gameTime - currentTime === 0) {
                    this.setState({ freezeAllElement: true });
                    clearInterval(this.mainInterval);
                    setTimeout(() => {
                        this.props.loseGame();
                    }, failurePauseTime*1000);
                }
            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.mainInterval);
    }

    toggleTarget = (key) => {
        this.setState({ holdingTargetKey: (this.state.holdingTargetKey === null) ? key : null });
    }

    switchTarget = (key) => {
        this.setState((prevState) => ({ 
            ...prevState,
            holdingTargetKey: null,
            targets: {
                ...prevState.targets,
                [prevState.holdingTargetKey]: {
                    ...prevState.targets[prevState.holdingTargetKey],
                    seatIndex: prevState.targets[key].seatIndex
                },
                [key]: {
                    ...prevState.targets[key],
                    seatIndex: prevState.targets[prevState.holdingTargetKey].seatIndex
                }
            }
        }), () => {
            const restTargets = Object.keys(this.state.targets).filter(key => (parseInt(this.state.targets[key].seatIndex)!==parseInt(key)));
            if(restTargets.length===0) {
                this.setState({ freezeAllElement: true });
                clearInterval(this.mainInterval);
                this.setState({ finishedActive: true });
                setTimeout(() => {
                    this.props.winGame(2);
                }, successPauseTime*1000);
            }
        });
    }

    render() {
        return (
            <div className={
                `${styles_Basic.BackgroundWrapper}`
                + ` ${this.state.freezeAllElement ? styles.Freeze : '' }`
            }>                
                <img className={styles_Basic.BackgroundImg} src={data.backgroundUrl} />

                {data.assets.map((asset, index) => 
                    <div 
                        key={index}
                        className={styles_Basic.AssetWrapper}
                        style={{
                            width: asset.width,
                            left: asset.x,
                            top: asset.y,
                        }}
                    >
                        <LazyImage
                            loadLazyImage={this.props.loadLazyImage}
                            // ref={ref => assetsRef[index] = ref}
                            src={asset.url}
                            attributes={{
                                draggable: false,
                                alt: ""
                            }}
                        />
                    </div>
                    
                )}
                
                <GameBar 
                    colorType="Yellow"
                    gameTime={gameTime}
                    countingTime={this.state.countingTime}
                    isPausing={this.state.isPausing}
                    isFreezingAllElement={this.state.freezeAllElement}
                    resumeGame={this.props.resumeGame}
                    pauseGame={this.props.pauseGame}
                    loadLazyImage={this.props.loadLazyImage}
                />
                
                <div className={styles.SeatsWrapper}>
                    <LazyImage
                        loadLazyImage={this.props.loadLazyImage}
                        src={Frame}
                        attributes={{
                            className: styles.Frame,
                            draggable: false,
                            alt: ""
                        }}
                    />
                    <Targets
                        targetsData={data.targets}
                        targetsSeatData={this.state.targets}
                        loadLazyImage={this.props.loadLazyImage}
                        toggleTarget={this.toggleTarget}
                        switchTarget={this.switchTarget}
                        holdingTargetKey={this.state.holdingTargetKey}
                    />
                </div>

                <SuccessAnimation 
                    loadLazyImage={this.props.loadLazyImage}
                    isActive={this.state.finishedActive}
                />
            </div>
        );
    }
}

export default Puzzling;