import Puzzling from "./Puzzling.jsx";

import { data as coverData } from "./data/cover.js";
import { data as modalData } from "./data/modal.js";

export const data = {
    name: "拼湊回憶",
    component: Puzzling,
    ruleDescription: "將錯亂的畫面拚回正確的回憶",
    modal: modalData,
    cover: coverData
}