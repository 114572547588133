import React, { useContext } from "react";
import ClickSoundContext from "./context.js";

const SoundButton = props => {
    const { playClickSound } = useContext(ClickSoundContext);

    return (
        <a
            key={props.key}
            className={props.className} 
            onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                playClickSound();
                props.onClick();
            }} 
            style={{...props.style}}
        >{React.cloneElement(props.children)}</a>
    )
}

export default SoundButton;