import EndingBG from "./../assets/modal/ending-BG.jpg";
// import EndingBG from "./../assets/modal/ending-tmp.jpg";
import Wrapper from "./../assets/modal/wrapper.png";
import Wrapper_2 from "./../assets/modal/wrapper-2.png";
import WrapperTitle from "./../assets/modal/wrapper-title.png";
import WrapperText from "./../assets/modal/wrapper-text.png";
import WrapperButtonStart from "./../assets/modal/wrapper-button-start.png";
import WrapperButtonContinue from "./../assets/modal/wrapper-button-continue.png";
import WrapperButtonGoNextPage from "./../assets/modal/wrapper-button-go-next-page.png";
import WrapperButtonRestart from "./../assets/modal/wrapper-button-restart.png";

import ButtonRestart from "./../assets/modal/button-restart.png";
import ButtonGoNextPage from "./../assets/modal/button-go-next-page.png";

import TextSuccess from "./../assets/modal/text-success.png";
import TextFailure from "./../assets/modal/text-failure.png";

import EndingBalls from "./../assets/modal/ending-balls.png";
import EndingLightLine from "./../assets/modal/ending-light-line.png";
import EndingFye from "./../assets/modal/ending-fye.png";
import EndingLightHoll from "./../assets/modal/ending-light-holl.png";
import EndingLines from "./../assets/modal/ending-lines.png";
import EndingStars from "./../assets/modal/ending-stars.png";

const FunctionType = {
    CloseModal: "CloseMoal",
    RestartGame: "RestartGame",
    BackToStory: "BackToStory",
};

export const data = {
    Landing: {
        backgroundUrl: null,
        buttons: [
            {
                functionType: FunctionType.CloseModal,
                url: WrapperButtonStart,
                width: '16.7%',
                x: '49.3%',
                y: '59.9%',
            }
        ],
        assets: [
            {
                url: Wrapper,
                width: '46.8%',
                x: '49.1%',
                y: '54.1%',
            },
            {
                url: WrapperTitle,
                width: '35.5%',
                x: '49.4%',
                y: '38%',
            },
            {
                url: WrapperText,
                width: '26.6%',
                x: '50.6%',
                y: '50.4%',
            }
        ]
    },
    Pausing: {
        backgroundUrl: null,
        buttons: [
            {
                functionType: FunctionType.CloseModal,
                url: WrapperButtonContinue,
                width: '16%',
                x: '49.7%',
                y: '54.5%',
            },
            {
                functionType: FunctionType.RestartGame,
                url: WrapperButtonRestart,
                width: '16.5%',
                x: '40.9%',
                y: '62.1%',
            },
            {
                functionType: FunctionType.BackToStory,
                url: WrapperButtonGoNextPage,
                width: '16.2%',
                x: '59.3%',
                y: '62.2%',
            }
        ],
        assets: [
            {
                url: Wrapper_2,
                width: '58.4%',
                x: '50%',
                y: '52.1%',
            },
            {
                url: WrapperTitle,
                width: '32.4%',
                x: '49.6%',
                y: '33.2%',
            },
            {
                url: WrapperText,
                width: '25.7%',
                x: '50%',
                y: '45%',
            }
        ]
    },
    Failure: {
        backgroundUrl: EndingBG,
        buttons: [
            {
                functionType: FunctionType.RestartGame,
                url: ButtonRestart,
                width: '13.9%',
                x: '70.7%',
                y: '67.4%',
            },
            {
                functionType: FunctionType.BackToStory,
                url: ButtonGoNextPage,
                width: '14.1%',
                x: '70.7%',
                y: '76.2%',
            }
        ],
        assets: [
            {
                url: EndingBalls,
                width: '76%',
                x: '50.7%',
                y: '47.5%',
            },
            {
                url: EndingLightLine,
                width: '77%',
                x: '38.5%',
                y: '55%',
            },
            {
                url: EndingFye,
                width: '22.6%',
                x: '35.8%',
                y: '57.5%',
            },
            {
                url: EndingLightHoll,
                width: '37%',
                x: '60.1%',
                y: '47.5%',
            },
            {
                url: EndingLines,
                width: '100%',
                x: '50%',
                y: '50%',
            },
            {
                url: EndingStars,
                width: '72%',
                x: '54%',
                y: '40.3%',
            },
            {
                url: TextFailure,
                width: '15.9%',
                x: '62.8%',
                y: '51%',
            },
        ]
    },
    Success: {
        backgroundUrl: EndingBG,
        buttons: [
            {
                functionType: FunctionType.RestartGame,
                url: ButtonRestart,
                width: '13.9%',
                x: '70.7%',
                y: '67.4%',
            },
            {
                functionType: FunctionType.BackToStory,
                url: ButtonGoNextPage,
                width: '14.1%',
                x: '70.7%',
                y: '76.2%',
            }
        ],
        assets: [
            {
                url: EndingBalls,
                width: '76%',
                x: '50.7%',
                y: '47.5%',
            },
            {
                url: EndingLightLine,
                width: '77%',
                x: '38.5%',
                y: '55%',
            },
            {
                url: EndingFye,
                width: '22.6%',
                x: '35.8%',
                y: '57.5%',
            },
            {
                url: EndingLightHoll,
                width: '37%',
                x: '60.1%',
                y: '47.5%',
            },
            {
                url: EndingLines,
                width: '100%',
                x: '50%',
                y: '50%',
            },
            {
                url: EndingStars,
                width: '72%',
                x: '54%',
                y: '40.3%',
            },
            {
                url: TextSuccess,
                width: '18%',
                x: '62.8%',
                y: '51%',
            },
        ]
    }
};