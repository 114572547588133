import BG from "./assets/BG.jpg";
// import BG from "./assets/tmp.jpg";

import Animals from "./assets/animals.png";
import MeteorLeft from "./assets/meteor-left.png";
import MeteorCenter from "./assets/meteor-center.png";
import MeteorRight from "./assets/meteor-right.png";
import HandWriteText from "./assets/hand-write-text.png";
import Text from "./assets/text.png";

import animations from "./../../../book-renderer/Animation.module.scss";

export const data = {
    barComponent: null,
    mainText: '動物們和飛飛手牽著手，\n在浩瀚的宇宙外太空飛著。\n我們都是彼此的英雄。',
    mainTextDelay: 1600,
    backgroundUrl: BG,
    assets: [
        {
            url: Animals,
            width: '71%',
            x: '54%',
            y: '45%',
            animeProperties: {
                translateY: ['10%', 0],
                duration: 2300,
                easing: 'easeOutQuart',
            },
        },
        {
            url: Text,
            width: '26.7%',
            x: '66.6%',
            y: '28.1%',
            hideOnMobile: true,
            animeProperties: {
                opacity: [0, 1],
                delay: 2800,
                duration: 800,
                easing: 'easeInOutSine',
            },
        },
        {
            url: HandWriteText,
            width: '44.7%',
            x: '48.1%',
            y: '61%',
            animeProperties: {
                opacity: [0, 1],
                delay: 4200,
                duration: 800,
                easing: 'easeInOutSine',
            },
        },
        {
            url: MeteorLeft,
            width: '7.9%',
            x: '14.1%',
            y: '59.8%',
            animeProperties: {
                translateX: ['600%', 0],
                translateY: ['-400%', 0],
                opacity: [0, 1],
                delay: 1000,
                duration: 500,
                easing: 'easeInOutSine',
            },
        },
        {
            url: MeteorCenter,
            width: '18.5%',
            x: '39.9%',
            y: '86.8%',
            animeProperties: {
                translateX: ['300%', 0],
                translateY: ['-200%', 0],
                opacity: [0, 1],
                delay: 1800,
                duration: 500,
                easing: 'easeInOutSine',
            },
        },
        {
            url: MeteorRight,
            width: '16.7%',
            x: '74.8%',
            y: '11.3%',
            animeProperties: {
                translateX: ['240%', 0],
                translateY: ['-200%', 0],
                opacity: [0, 1],
                delay: 2200,
                duration: 500,
                easing: 'easeInOutSine',
            },
        },
    ]
};