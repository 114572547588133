import React, { useState, useEffect, useRef, useMemo } from "react";
import { Modal } from "./../modal-marketing/Modal.jsx";
import { LazyImage } from "./../../LazyImage.jsx";
import MainText from "../../MainText.jsx";
import styles_Interface from "../../Interface.module.scss";
import styles_Basic from "./../../ScenceBasicElement.module.scss";
import styles from "./Story.module.scss";
import anime from "animejs";
import SoundButton from "../../sound-button/SoundButton.jsx";

const Mode = {
    HorizontalMode: "HorizontalMode",
    VerticalMode: "VerticalMode",
    MobileMode: "MobileMode"
}

const Story = props => {
    const [modalOpened, setModalOpened] = useState(false);
    const [activeMarketingIndex, setActiveMarketingIndex] = useState('');
    const scenceRef = useRef(null);
    let assetsRef = [];
    let marketingsRef = [];

    useEffect(() => {
        if(props.isActive) {
            assetsRef.map((assetRef, index) => {
                const assetData = props.data.assets[index];
                if(assetData.animeProperties) {
                    assetRef.style.transform = ""
                    setTimeout(() => {
                        anime({ 
                            targets: assetRef, 
                            ...assetData.animeProperties 
                        });
                    }, 0);
                }
            });
            marketingsRef.map((assetRef, index) => {
                const assetData = props.data.marketings[index];
                if(assetData.animeProperties) {
                    assetRef.style.transform = ""
                    setTimeout(() => {
                        anime({ 
                            targets: assetRef, 
                            ...assetData.animeProperties 
                        });
                    }, 0);
                }
            });
        } else {
            anime.remove(assetsRef);
            anime.remove(marketingsRef);
        }
    }, [props.isActive]);

    const openModal = (marketingIndex, marketingId) => {
        props.handleSectionSwitcherLocked(true);
        setActiveMarketingIndex(marketingIndex);
        setModalOpened(true);

        let preState = [...props.marketingsState];
        preState[marketingId] = true;
        props.setMarketingsState([...preState]);
    }

    const closeModal = () => {
        props.handleSectionSwitcherLocked(false);
        setModalOpened(false);
    }

    // return useMemo(() => {
        return (
            <>
                <div 
                    className={
                        `${styles_Interface[props.interfaceMode]}`
                        + ` ${styles.Wrapper}`
                    }
                    ref={scenceRef}
                >
                    <div className={styles_Basic.BackgroundWrapper}>
                        {/* Avoid LazyLoad */}
                        <img
                            src={props.data.backgroundUrl}
                            className={styles_Basic.BackgroundImg}
                            draggable={false}
                        />
                        {props.data.barComponent && React.createElement(props.data.barComponent, {
                            handleSectionSwitcherLocked: props.handleSectionSwitcherLocked,
                            loadLazyImage: props.loadLazyImage,
                            modalParent: scenceRef.current,
                            interfaceMode: props.interfaceMode
                        })}
                        {props.data.assets.map((asset, index) => 
                            <div 
                                key={index}
                                className={styles_Basic.AssetWrapper}
                                style={{
                                    width: asset.width,
                                    left: asset.x,
                                    top: asset.y,
                                    display: props.interfaceMode===Mode.MobileMode ?
                                        (asset.hideOnMobile ? 'none' : 'block') :
                                        (asset.hideOnDesktop ? 'none' : 'block'),
                                    transform: `translate(-50%, -50%)${asset.rotate ? ` rotate(${asset.rotate})` : ''}`,
                                    zIndex: asset.zIndex || '2'
                                }}
                            >
                                <LazyImage
                                    loadLazyImage={props.loadLazyImage}
                                    ref={ref => assetsRef[index] = ref}
                                    src={asset.url}
                                    attributes={{
                                        className: (props.isActive && asset.animation) ? asset.animation : '',
                                        draggable: false,
                                        alt: ""
                                    }}
                                />
                            </div>
                        )}

                        {props.data.marketings && props.data.marketings.map((asset, index) => 
                            <SoundButton
                                key={index}
                                className={`${styles_Basic.AssetWrapper} ${styles_Basic.Button}`}
                                style={{
                                    width: asset.width,
                                    left: asset.x,
                                    top: asset.y,
                                }}
                                onClick={() => openModal(index, asset.id)}
                            >
                                <>
                                    <LazyImage
                                        loadLazyImage={props.loadLazyImage}
                                        ref={ref => marketingsRef[index] = ref}
                                        src={asset.url}
                                        attributes={{
                                            draggable: false,
                                            alt: ""
                                        }}
                                    />
                                    {asset.attaches && asset.attaches.map((attach, jdex) => 
                                        <div 
                                            key={jdex}
                                            className={styles_Basic.AssetWrapper}
                                            style={{
                                                width: attach.width,
                                                left: attach.x,
                                                top: attach.y,
                                                zIndex: attach.zIndex || 2
                                            }}
                                        >
                                            <LazyImage
                                                loadLazyImage={props.loadLazyImage}
                                                src={attach.url}
                                                attributes={{
                                                    className: attach.animation,
                                                    draggable: false,
                                                    alt: ""
                                                }}
                                            />
                                        </div>
                                    )}
                                </>
                            </SoundButton>
                        )}
                        
                        {/* <div className={`${styles_Basic.ModalShadow} ${modalOpened ? styles_Basic.Active : ''}`} />  */}
                        {props.data.marketings && props.data.marketings.map((asset, index) => 
                            <Modal
                                key={index}
                                loadLazyImage={props.loadLazyImage}
                                parent={scenceRef.current}
                                isOpen={activeMarketingIndex===index && modalOpened}
                                closeModal={closeModal}
                                content={asset.content}
                                interfaceMode={props.interfaceMode}
                            />
                        )}

                        {props.data.buttons && props.data.buttons.map((asset, index) => 
                            <SoundButton
                                key={index}
                                className={`${styles_Basic.AssetWrapper} ${styles_Basic.Button}`}
                                style={{
                                    width: asset.width,
                                    left: asset.x,
                                    top: asset.y,
                                    display: props.interfaceMode===Mode.MobileMode ?
                                        (asset.hideOnMobile ? 'none' : 'block') :
                                        (asset.hideOnDesktop ? 'none' : 'block'),
                                }}
                                onClick={asset.clickEvent}
                            >
                                <LazyImage
                                    loadLazyImage={props.loadLazyImage}
                                    // ref={ref => marketingsRef[index] = ref}
                                    src={asset.url}
                                    attributes={{
                                        className: (props.isActive && asset.animation) ? asset.animation : '',
                                        draggable: false,
                                        alt: ""
                                    }}
                                />
                            </SoundButton>
                        )}
                    </div>
                </div>
                <MainText 
                    interfaceMode={props.interfaceMode} 
                    text={props.data.mainText} 
                    isActive={props.isActive} 
                    delay={props.data.mainTextDelay}
                />
            </>
        )
    // }, [props.isActive, props.interfaceMode, props.gamesState, props.marketingsState, modalOpened, activeMarketingIndex]);
}

Story.displayName = 'Story';

export default Story;