import BG from "./../assets/cover/BG.jpg";
// import BG from "./../assets/cover/tmp.jpg";

import Title from "./../assets/cover/title.png";
import Text from "./../assets/cover/text.png";
import ShatteredGlassLeft from "./../assets/cover/shattered-glass-left.png";
import ShatteredGlassRight from "./../assets/cover/shattered-glass-right.png";

import ButtonStart from "./../assets/cover/button-start.png";

export const data = {
    mainText: '飛飛開始拼起那些沒戴魚缸前的記憶拼圖。',
    mainTextDelay: 2600,
    backgroundUrl: BG,
    startButton: {
        url: ButtonStart,
        width: '19.2%',
        x: '67.7%',
        y: '74.7%',
    },
    assets: [
        {
            url: ShatteredGlassLeft,
            width: '16.7%',
            x: '23.3%',
            y: '53.2%',
            animeProperties: {
                scale: [0.95, 1],
                opacity: [0.6, 1],
                rotate: ['5deg', 0],
                translateX: ['25%', 0],
                translateY: ['20%', 0],
                duration: 2600,
                easing: 'easeOutSine'
            },
        },
        {
            url: ShatteredGlassRight,
            width: '24.1%',
            x: '79%',
            y: '53.2%',
            animeProperties: {
                scale: [0.95, 1],
                opacity: [0.6, 1],
                rotate: ['-5deg', 0],
                translateX: ['-25%', 0],
                translateY: ['20%', 0],
                duration: 2600,
                easing: 'easeOutSine'
            },
        },
        {
            url: Text,
            width: '31.1%',
            x: '51.3%',
            y: '36.6%',
            hideOnMobile: true,
            animeProperties: {
                opacity: [0, 1],
                delay: 2600,
                duration: 800,
                easing: 'easeInOutSine',
            },
        },
        {
            url: Title,
            width: '38.8%',
            x: '51.9%',
            y: '27.6%',
        },
    ]
};