import MarketingElephant from "./assets/marketing-elephant.png";
import MarketingElephantMB from "./assets/marketing-elephant-mb.png";
import MarketingSlowAngel from "./assets/marketing-slow-angel.png";
import MarketingSlowAngelMB from "./assets/marketing-slow-angel-mb.png";
import MarketingCheckButton from "./assets/marketing-check-button.png";
import MarketingGrowCalendar from "./assets/marketing-grow-calendar.png";
import MarketingGrowCalendarMB from "./assets/marketing-grow-calendar-mb.png";
import MarketingAbout from "./assets/marketing-about.png";
import MarketingAboutMB from "./assets/marketing-about-mb.png";

export const data = [
    {
        url: MarketingElephant,
        urlMB: MarketingElephantMB,
        buttons: [],
    },
    {
        url: MarketingSlowAngel,
        urlMB: MarketingSlowAngelMB,
        buttons: [
            {
                url: MarketingCheckButton,
                width: '20%',
                x: '84.5%',
                y: '15%',
                hideOnMobile: true,
                linkUrl: 'http://kids.eden.org.tw/?action=screening',
            },
            {
                url: MarketingCheckButton,
                width: '50%',
                x: '50%',
                y: '93.3%',
                hideOnDesktop: true,
                linkUrl: 'http://kids.eden.org.tw/?action=screening',
            },
        ]
    },
    {
        url: MarketingGrowCalendar,
        urlMB: MarketingGrowCalendarMB,
        buttons: [
            {
                url: MarketingCheckButton,
                width: '25%',
                x: '50%',
                y: '93.5%',
                hideOnMobile: true,
                linkUrl: 'http://kids.eden.org.tw/?action=screening',
            },
            {
                url: MarketingCheckButton,
                width: '50%',
                x: '50%',
                y: '95%',
                hideOnDesktop: true,
                linkUrl: 'http://kids.eden.org.tw/?action=screening',
            },
        ]
    },
    {
        url: MarketingAbout,
        urlMB: MarketingAboutMB,
        buttons: []
    }
]