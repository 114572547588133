import BG from "./assets/BG.jpg";
// import BG from "./assets/tmp-mb.jpg";
import Header from "../../../book-renderer/template/story/headers/back-cover/Header.jsx";

import AnimalFye from "./assets/animal-fye.png";
import AnimalPenguin from "./assets/animal-penguin.png";
import AnimalPenguinHand from "./assets/animal-penguin-hand.png";
import AnimalElephant from "./assets/animal-elephant.png";
import AnimalElephantEar from "./assets/animal-elephant-ear.png";
import AnimalGiraffe from "./assets/animal-giraffe.png";
import AnimalGiraffeEye from "./assets/animal-giraffe-eye.png";
import AnimalDog from "./assets/animal-dog.png";
import AnimalLion from "./assets/animal-lion.png";
import Table from "./assets/table.png";
import TableMB from "./assets/table-mb.png";
import TableGlowing from "./assets/table-glowing.png";
import Text from "./assets/text.png";
import TextMB from "./assets/text-mb.png";
import ButtonShare from "./assets/button-share.png";
import ButtonDonate from "./assets/button-donate.png";
import ButtonShareMB from "./assets/button-share-mb.png";
import ButtonDonateMB from "./assets/button-donate-mb.png";

export const data = {
    barComponent: Header,
    mainText: '陪伴是最珍貴的給予。\n魚缸讓飛飛忘記傾聽，\n忘記自己也能索求幫助，\n直到失去方向才找回自己成為英雄的理由。\n「我們都是彼此的英雄。」\n正在看著這些文字的你，也是。\n\n\n\n',
    mainTextDelay: 1200,
    backgroundUrl: BG,
    assets: [
        {
            url: AnimalElephantEar,
            width: '8.2%',
            x: '24.55%',
            y: '47.5%',
            animeProperties: {
                translateX: [0, '-5%'],
                rotate: [0, '4deg'],
                delay: 300,
                duration: 1000,
                direction: 'alternate',
                loop: true,
                easing: 'easeInOutSine'
            },
        },
        {
            url: AnimalElephant,
            width: '15.5%',
            x: '32.6%',
            y: '50.2%',
        },
        {
            url: AnimalGiraffe,
            width: '15.8%',
            x: '64.4%',
            y: '37.9%',
        },
        {
            url: AnimalGiraffeEye,
            width: '4%',
            x: '68.5%',
            y: '30.5%',
            rotate: '14deg',
            animeProperties: {
                transformOrigin: 'bottom',
                scaleY: [
                    { value: [1, 0.1], duration: 60, delay: 2200, direction: 'alternate' },
                ],
                loop: true,
                easing: 'easeOutSine'
            },
        },
        {
            url: AnimalDog,
            width: '15.3%',
            x: '67.9%',
            y: '54.7%',
            animeProperties: {
                translateX: [0, '1%'],
                translateY: [0, '-1%'],
                rotate: [0, '-2deg'],
                delay: 2000,
                duration: 500,
                direction: 'alternate',
                loop: true,
                easing: 'easeInOutSine'
            },
        },
        {
            url: AnimalLion,
            width: '39.1%',
            x: '56.5%',
            y: '72.7%',
        },
        {
            url: TableGlowing,
            width: '31.4%',
            x: '50%',
            y: '54.1%',
            animeProperties: {
                opacity: [1, 0.5],
                scale: [1, 1.1],
                duration: 3000,
                direction: 'alternate',
                loop: true,
                easing: 'easeInOutSine'
            },
        },
        {
            url: Table,
            width: '30.2%',
            x: '50%',
            y: '53.7%',
            hideOnMobile: true,
        },
        {
            url: TableMB,
            width: '30%',
            x: '50%',
            y: '53.6%',
            hideOnDesktop: true,
        },
        {
            url: AnimalFye,
            width: '18.1%',
            x: '47.4%',
            y: '28.3%',
        },
        {
            url: AnimalPenguin,
            width: '15.9%',
            x: '40.5%',
            y: '73%',
        },
        {
            url: AnimalPenguinHand,
            width: '14.2%',
            x: '41.4%',
            y: '71.8%',
            animeProperties: {
                translateY: ['2%', '-2%'],
                rotate: [0, '-4deg'],
                delay: 1200,
                duration: 600,
                direction: 'alternate',
                loop: true,
                easing: 'easeInOutSine'
            },
        },
        {
            url: Text,
            width: '20.6%',
            x: '50.35%',
            y: '48.3%',
            hideOnMobile: true,
        },
        {
            url: TextMB,
            width: '20.6%',
            x: '50.35%',
            y: '48.3%',
            hideOnDesktop: true,
        },
    ],
    buttons: [
        {
            url: ButtonShare,
            width: '9.7%',
            x: '46.4%',
            y: '63.4%',
            hideOnMobile: true,
            clickEvent: () => window.open(`https://www.facebook.com/dialog/feed?app_id=1514064148774319&display=popup&link=https://lovekidstory.eden.org.tw/`, '', 'left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0')
        },
        {
            url: ButtonShareMB,
            width: '11.5%',
            x: '50%',
            y: '63.4%',
            hideOnDesktop: true,
            clickEvent: () => window.open(`https://www.facebook.com/dialog/feed?app_id=1514064148774319&display=popup&link=https://lovekidstory.eden.org.tw/`, '', 'left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0')
        },
        {
            url: ButtonDonate,
            width: '9.7%',
            x: '54.1%',
            y: '63.5%',
            hideOnMobile: true,
            clickEvent: () => window.open('https://bit.ly/2VhkdE0', '_blank')
        },
        {
            url: ButtonDonateMB,
            width: '11.4%',
            x: '50%',
            y: '56.5%',
            hideOnDesktop: true,
            clickEvent: () => window.open('https://bit.ly/2VhkdE0', '_blank')
        }
    ]
};