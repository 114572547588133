import React from "react";
import BG_mb from "./template/assets/BG-mb.png";

const Mode = {
    HorizontalMode: "HorizontalMode",
    VerticalMode: "VerticalMode",
    MobileMode: "MobileMode"
};

const Page = props => 
    <div 
        className="section"
        style={{ 
            overflow: 'hidden',
            backgroundColor: props.backgroundColor,
            backgroundImage: props.mode===Mode.MobileMode ? `url(${BG_mb})` : '',
            backgroundSize: "cover",
            backgroundPosition: "center"
        }}
    >
        <div className="page-inner">
            {props.children}
        </div>
    </div>

export default Page;