import BG from "./../assets/cover/BG.jpg";
// import BG from "./../assets/cover/tmp.jpg";

import ButtonStart from "./../assets/cover/button-start.png";
import BGCenter from "./../assets/cover/center.png";
import BGShadow from "./../assets/cover/shadow.png";
import BGBlue from "./../assets/cover/blue.png";
import BGRed from "./../assets/cover/red.png";
import BlackHoll from "./../assets/cover/black-holl.png";
import LinesColor from "./../assets/cover/lines-color.png";
import LinesWhite from "./../assets/cover/lines-white.png";
import Balls from "./../assets/cover/balls.png";
import Stars from "./../assets/cover/stars.png";
// import Meteor from "./../assets/cover/meteor.png";
// import StarWhite from "./../assets/cover/star-white.png";
// import StarDimond from "./../assets/cover/star-dimond.png";
import Fye from "./../assets/cover/fye.png";
import Title from "./../assets/cover/title.png";
import Text from "./../assets/cover/text.png";

import Numbers from "./../assets/cover/numbers.png";

export const data = {
    mainText: '飛飛掉進了迷樣的數字黑洞中，\n順著發亮的數字依序點擊，逃出黑洞。',
    mainTextDelay: 2600,
    backgroundUrl: BG,
    startButton: {
        url: ButtonStart,
        width: '17.5%',
        x: '64.7%',
        y: '62.8%',
    },
    assets: [
        // {
        //     url: BGCenter,
        //     width: '80.1%',
        //     x: '40%',
        //     y: '50%',
        // },
        // {
        //     url: BGShadow,
        //     width: '100%',
        //     x: '50%',
        //     y: '50%',
        // },
        {
            url: BGBlue,
            width: '100%',
            x: '50%',
            y: '50%',
            animeProperties: {
                opacity: [0.4, 1],
                duration: 4000,
                loop: true,
                direction: 'alternate',
                easing: 'linear',
            },
        },
        {
            url: BGRed,
            width: '100%',
            x: '50%',
            y: '50%',
        },
        {
            url: BlackHoll,
            width: '24.7%',
            x: '33.8%',
            y: '55.5%',
            animeProperties: {
                scale: [1, 0.95],
                opacity: [1, 0.8],
                duration: 3000,
                loop: true,
                direction: 'alternate',
                easing: 'easeInOutQuad',
                translateZ: 0,
            },
        },
        {
            url: LinesColor,
            width: '30.8%',
            x: '34.5%',
            y: '53.6%',
            animeProperties: {
                scale: [1, 1.05],
                duration: 3000,
                loop: true,
                direction: 'alternate',
                easing: 'easeInOutQuad',
                translateZ: 0,
            },
        },
        {
            url: LinesWhite,
            width: '81.9%',
            x: '50%',
            y: '50%',
        },
        {
            url: Balls,
            width: '46%',
            x: '38.15%',
            y: '51.65%',
            animeProperties: {
                translateY: ['5%', 0],
                opacity: [0, 1],
                duration: 3000,
                // easing: 'cubicBezier(0, 0, 0, 1)',
                easing: 'easeInOutQuad',
            },
        },
        {
            url: Stars,
            width: '84.6%',
            x: '46.8%',
            y: '50.6%',
        },
        // {
        //     url: Meteor,
        //     width: '82.1%',
        //     x: '45.85%',
        //     y: '53.2%',
        // },
        // {
        //     url: StarWhite,
        //     width: '81.3%',
        //     x: '48.5%',
        //     y: '50.4%',
        // },
        // {
        //     url: StarDimond,
        //     width: '74.8%',
        //     x: '42.6%',
        //     y: '45.7%',
        // },
        {
            url: Fye,
            width: '21.7%',
            x: '38.4%',
            y: '51.1%',
        },
        {
            url: Title,
            width: '29.6%',
            x: '62.4%',
            y: '40.8%',
        },
        {
            url: Text,
            width: '25.2%',
            x: '65%',
            y: '74.1%',
            hideOnMobile: true,
            animeProperties: {
                opacity: [0, 1],
                delay: 2600,
                duration: 800,
                easing: 'easeInOutSine',
            },
        },
        {
            url: Numbers,
            coverAll: true,
            pointerEvents: 'none',
            width: '67.2%',
            x: '56%',
            y: '56.5%',
        },
    ]
};