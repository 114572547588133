import BG from "./../assets/game/BG.jpg";
// import BG from "./../assets/game/tmp.jpg";
// import BG from "./../assets/modal/wrapper-tmp.jpg";
// import BG from "./../assets/modal/wrapper-start-tmp.jpg";
// import BG from "./../assets/modal/wrapper-tmp-1.jpg";
// import BG from "./../assets/modal/wrapper-tmp-2.jpg";

import FruitApple3 from "./../assets/game/fruit-apple-3.png";
import FruitApple2 from "./../assets/game/fruit-apple-2.png";
import FruitApple1 from "./../assets/game/fruit-apple-1.png";
import FruitApple from "./../assets/game/fruit-apple-0.png";
import FruitAppleIce from "./../assets/game/fruit-apple-0-ice.png";
import FruitBallet3 from "./../assets/game/fruit-ballet-3.png";
import FruitBallet2 from "./../assets/game/fruit-ballet-2.png";
import FruitBallet1 from "./../assets/game/fruit-ballet-1.png";
import FruitBallet from "./../assets/game/fruit-ballet-0.png";
import FruitBalletIce from "./../assets/game/fruit-ballet-0-ice.png";
import FruitBanana3 from "./../assets/game/fruit-banana-3.png";
import FruitBanana2 from "./../assets/game/fruit-banana-2.png";
import FruitBanana1 from "./../assets/game/fruit-banana-1.png";
import FruitBanana from "./../assets/game/fruit-banana-0.png";
import FruitBananaIce from "./../assets/game/fruit-banana-0-ice.png";
import FruitCherry3 from "./../assets/game/fruit-cherry-3.png";
import FruitCherry2 from "./../assets/game/fruit-cherry-2.png";
import FruitCherry1 from "./../assets/game/fruit-cherry-1.png";
import FruitCherry from "./../assets/game/fruit-cherry-0.png";
import FruitCherryIce from "./../assets/game/fruit-cherry-0-ice.png";
import FruitGrape3 from "./../assets/game/fruit-grape-3.png";
import FruitGrape2 from "./../assets/game/fruit-grape-2.png";
import FruitGrape1 from "./../assets/game/fruit-grape-1.png";
import FruitGrape from "./../assets/game/fruit-grape-0.png";
import FruitGrapeIce from "./../assets/game/fruit-grape-0-ice.png";
import FruitMango3 from "./../assets/game/fruit-mango-3.png";
import FruitMango2 from "./../assets/game/fruit-mango-2.png";
import FruitMango1 from "./../assets/game/fruit-mango-1.png";
import FruitMango from "./../assets/game/fruit-mango-0.png";
import FruitMangoIce from "./../assets/game/fruit-mango-0-ice.png";
import FruitOrange3 from "./../assets/game/fruit-orange-3.png";
import FruitOrange2 from "./../assets/game/fruit-orange-2.png";
import FruitOrange1 from "./../assets/game/fruit-orange-1.png";
import FruitOrange from "./../assets/game/fruit-orange-0.png";
import FruitOrangeIce from "./../assets/game/fruit-orange-0-ice.png";
import FruitPeach3 from "./../assets/game/fruit-peach-3.png";
import FruitPeach2 from "./../assets/game/fruit-peach-2.png";
import FruitPeach1 from "./../assets/game/fruit-peach-1.png";
import FruitPeach from "./../assets/game/fruit-peach-0.png";
import FruitPeachIce from "./../assets/game/fruit-peach-0-ice.png";
import FruitPineapple3 from "./../assets/game/fruit-pineapple-3.png";
import FruitPineapple2 from "./../assets/game/fruit-pineapple-2.png";
import FruitPineapple1 from "./../assets/game/fruit-pineapple-1.png";
import FruitPineapple from "./../assets/game/fruit-pineapple-0.png";
import FruitPineappleIce from "./../assets/game/fruit-pineapple-0-ice.png";

export const data = {
    backgroundUrl: BG,
    targets: [
        {
            urls: [FruitApple1, FruitApple2, FruitApple3],
            width: "11.6%",
            x: "55.1%",
            y: "35.5%",
            complete: {
                urls: [FruitApple, FruitAppleIce],
                width: '12.7%',
                x: '56.05%',
                y: '35.9%',
            }
        },
        {
            urls: [FruitBallet1, FruitBallet2, FruitBallet3],
            width: '12.2%',
            x: '53.4%',
            y: '69.9%',
            complete: {
                urls: [FruitBallet, FruitBalletIce],
                width: '13.7%',
                x: '53.5%',
                y: '69.8%',
            }
        },
        {
            urls: [FruitBanana1, FruitBanana2, FruitBanana3],
            width: "12.05%",
            x: "62.4%",
            y: "53.3%",
            complete: {
                urls: [FruitBanana, FruitBananaIce],
                width: '10.6%',
                x: '62.35%',
                y: '53.3%',
            }
        },
        {
            urls: [FruitCherry1, FruitCherry2, FruitCherry3],
            width: '12.9%',
            x: '35.6%',
            y: '70.75%',
            complete: {
                urls: [FruitCherry, FruitCherryIce],
                width: '13.2%',
                x: '35.35%',
                y: '71.3%',
            }
        },
        {
            urls: [FruitGrape1, FruitGrape2, FruitGrape3],
            width: "11.8%",
            x: "70.7%",
            y: "35.45%",
            complete: {
                urls: [FruitGrape, FruitGrapeIce],
                width: '12.2%',
                x: '71.3%',
                y: '35%',
            }
        },
        {
            urls: [FruitMango1, FruitMango2, FruitMango3],
            width: "13.2%",
            x: "71.32%",
            y: "72.1%",
            complete: {
                urls: [FruitMango, FruitMangoIce],
                width: '17%',
                x: '72.4%',
                y: '71.7%',
            }
        },
        {
            urls: [FruitOrange1, FruitOrange2, FruitOrange3],
            width: '13.5%',
            x: '31.5%',
            y: '34.5%',
            complete: {
                urls: [FruitOrange, FruitOrangeIce],
                width: '9.5%',
                x: '31.35%',
                y: '36.3%',
            }
        },
        {
            urls: [FruitPeach1, FruitPeach2, FruitPeach3],
            width: '11.9%',
            x: '26.8%',
            y: '54.1%',
            complete: {
                urls: [FruitPeach, FruitPeachIce],
                width: '13.2%',
                x: '27.7%',
                y: '52.8%',
            }
        },
        {
            urls: [FruitPineapple1, FruitPineapple2, FruitPineapple3],
            width: '15.2%',
            x: '42.45%',
            y: '48%',
            complete: {
                urls: [FruitPineapple, FruitPineappleIce],
                width: '14.1%',
                x: '41.6%',
                y: '49.25%',
            }
        },
    ],
    assets: [

    ]
};