import React, { useState } from "react";
import { LazyImage } from "../../../book-renderer/LazyImage.jsx";
import SoundButton from "./../../../book-renderer/sound-button/SoundButton.jsx";
import { data } from './index.js';
import styles from "./GameBar.module.scss";
import useSound from "use-sound";
import clockUrgentMp3 from "./assets/clock-urgent.mp3";
import { useEffect } from "react";

const GameBar = props => {
    const [play, { stop }] = useSound(clockUrgentMp3);
    const [isInUrgent, setIsInUrgent] = useState(false);
    const [numberInUrgent, setNumberInUrgent] = useState(false);

    useEffect(() => {
        const restTime = props.gameTime-props.countingTime;
        if(restTime>0 && restTime<=10) setIsInUrgent(true);
        if(restTime<=0) setIsInUrgent(false);

        if(restTime>0 && restTime<=10) {
            setNumberInUrgent(true);
            setTimeout(() => {
                setNumberInUrgent(false);
            }, 500);
        }
    }, [props.countingTime]);

    useEffect(() => {
        if(isInUrgent) play();
        else stop();
    }, [isInUrgent]);

    useEffect(() => {
        if(props.isPausing || props.isFreezingAllElement) setIsInUrgent(false); 
    }, [props.isPausing, props.isFreezingAllElement]);

    return (
        <>
            <div className={styles.TimerWrapper}>
                <LazyImage
                    loadLazyImage={props.loadLazyImage}
                    src={data[props.colorType].clock}
                    attributes={{
                        className: `${styles.Clock} ${isInUrgent ? styles.ClockSwagging : ''}`,
                        draggable: false,
                        alt: ""
                    }}
                />
                <LazyImage
                    loadLazyImage={props.loadLazyImage}
                    src={data[props.colorType].progressBase}
                    attributes={{
                        className: styles.ProgressBase,
                        draggable: false,
                        alt: ""
                    }}
                />
                <div className={styles.InnerWrapper}>
                    <div className={styles.ProgressWrapper}>
                        <img 
                            className={styles.Progress} 
                            src={data[props.colorType].progress}
                            style={{ marginLeft: `-${(props.countingTime)*100 / (props.gameTime + 2)}%` }}
                        />
                    </div>
                    <div className={`${styles.NumbersWrapper} ${numberInUrgent ? styles.Beating : ''}`}>
                        <div className={styles.NumberWrapper}>
                            <img 
                                src={data[props.colorType].numbers} 
                                style={{ left: `${-100*(Math.floor((props.gameTime-props.countingTime)/10))}%` }} 
                                alt="" 
                            />
                        </div>
                        <div className={styles.NumberWrapper}>
                            <img 
                                src={data[props.colorType].numbers} 
                                style={{ left: `${-100*((props.gameTime-props.countingTime)%10)}%` }} 
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
            <SoundButton 
                className={styles.Pauser} 
                onClick={() => props.isPausing ? props.resumeGame() : props.pauseGame() }
            >
                <LazyImage
                    loadLazyImage={props.loadLazyImage}
                    src={data[props.colorType].paulser}
                    attributes={{
                        draggable: false,
                        alt: ""
                    }}
                />
            </SoundButton>
        </>
    )
}

export default GameBar;