import BG from "./assets/BG.jpg";
// import BG from "./assets/tmp.jpg";
// import Star from "./assets/star.png";
// import Plant from "./assets/plant.png";
// import Ice from "./assets/ice.png";
// import Bomb from "./assets/bomb.png";
import Cloud from "./assets/cloud.png";
import Eyelid from "./assets/eyelid.png";
import HandWriteText from "./assets/hand-write-text.png";
import DarkVision from "./assets/dark-vision.png";

import animations from "./../../assets/Animation.module.scss";

export const data = {
    barComponent: null,
    mainText: '飛飛睜開眼睛，\n好多張臉在他眼前。',
    mainTextDelay: 2600,
    backgroundUrl: BG,
    assets: [
        {
            url: Cloud,
            width: '57%',
            x: '51.6%',
            y: '32.5%',
            animation: animations.VisionBlur,
            animeProperties: {
                translateX: ['2%', '-5%'],
                direction: 'alternate',
                duration: 14000,
                loop: true,
                easing: 'easeInOutSine',
            },
        },
        {
            url: Eyelid,
            width: '100%',
            x: '50%',
            y: '50%',
            animation: animations.VisionBlur
        },
        {
            url: HandWriteText,
            width: '37.3%',
            x: '49%',
            y: '52.2%',
            animeProperties: {
                opacity: [0, 1],
                delay: 2600,
                duration: 800,
                easing: 'easeInOutSine'
            },
        },
        {
            url: DarkVision,
            width: '100%',
            x: '50%',
            y: '50%',
            animation: animations.EyelidOpacity
        }
    ]
};