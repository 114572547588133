import React from "react";
import { LazyImage } from "../../../book-renderer/LazyImage.jsx";
import styles from "./Targets.module.scss";

import useSound from "use-sound";
import breakIceMp3 from "./assets/game/break-ice.mp3";
import breakIceFinalMp3 from "./assets/game/break-ice-final.mp3";

export const Targets = props => {
    const [playBreakIce] = useSound(breakIceMp3);
    const [playBreakIceFinal] = useSound(breakIceFinalMp3);

    return (     
        Object.keys(props.targetsData).map((key) => {
            const targetData = props.targetsData[key];
            const targetFreezeData = props.targetsFreezeData[key];
            return (
                <div key={key}>
                    <div
                        className={styles.Target} 
                        style={{
                            width: targetData.width, 
                            left: targetData.x, 
                            top: targetData.y,
                            zIndex: targetFreezeData.freezeLevel===0 ? -1 : 1,
                            pointerEvents: targetFreezeData.freezeLevel===0 ? 'none' : '',
                        }}
                        onClick={e => {
                            e.preventDefault();
                            targetFreezeData.freezeLevel!==1 ? playBreakIce() : playBreakIceFinal();
                            props.setFreezeLevel(key, targetFreezeData.freezeLevel-1);
                        }}
                    >
                        {targetData && targetData.urls.map((url, index) => 
                            <LazyImage
                                key={index}
                                loadLazyImage={props.loadLazyImage}
                                src={url}
                                attributes={{
                                    className: (targetFreezeData.freezeLevel-1===index) ? styles.Active : '',
                                    draggable: false,
                                    alt: ""
                                }}
                            />
                        )}
                    </div>
                    <div
                        className={styles.Target} 
                        style={{
                            // draggable: false,
                            width: targetData.complete.width, 
                            left: targetData.complete.x, 
                            top: targetData.complete.y,
                            pointerEvents: 'none'
                        }}
                    >
                        <LazyImage
                            loadLazyImage={props.loadLazyImage}
                            src={targetData.complete.urls[0]}
                            attributes={{
                                className: 
                                    `${targetFreezeData.freezeLevel===0 ? styles.Complete : ''}`
                                    + ` ${(props.freezeAllElement && targetFreezeData.freezeLevel===0) ? styles.Swagging : ''}`,
                                draggable: false,
                                alt: ""
                            }}
                        />
                        <LazyImage
                            loadLazyImage={props.loadLazyImage}
                            src={targetData.complete.urls[1]}
                            attributes={{
                                className: 
                                    `${targetFreezeData.freezeLevel===0 ? styles.CompleteIce : ''}`
                                    + ` ${(props.freezeAllElement && targetFreezeData.freezeLevel===0) ? styles.Swagging : ''}`,
                                draggable: false,
                                alt: ""
                            }}
                        />
                    </div>
                </div>
            )
        })
    )
}