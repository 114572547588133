import React from "react";
import { LazyImage } from "../../../book-renderer/LazyImage.jsx";
import styles from "./Targets.module.scss";
import styles_Animations from "./Animation.module.scss";

import useSound from "use-sound";
import clickNumberMp3 from "./assets/game/click-number.mp3";
import clickNumberWrongMp3 from "./assets/game/click-number-wrong.mp3";

export const Targets = props => {
    const [playClickNumber] = useSound(clickNumberMp3);
    const [playClickNumberWrong] = useSound(clickNumberWrongMp3);

    return props.targetsData.map((target, index) => 
        <div
            className={
                `${styles.Target}`
                + ` ${!props.isPausing ? target.animation : ''}`
            } 
            style={{
                draggable: false,
                width: target.width,
                left: target.x, 
                top: target.y,
            }}
            onClick={e => {
                e.preventDefault();
                if(index > props.countingNumber) {
                    (index===props.countingNumber+1) ? playClickNumber() : playClickNumberWrong();
                    props.handleTargetClick(index)
                }
            }}
            key={index}
        >
            <LazyImage
                loadLazyImage={props.loadLazyImage}
                src={target.url}
                attributes={{
                    className:
                        `${(index <= props.countingNumber) ? styles.Active : styles.Original}`
                        + ` ${(!props.isPausing && index===0 && props.countingNumber<0 && props.countingTime>2) ? styles_Animations.Pointing : ''}`,
                    draggable: false,
                    alt: ""
                }}
            />
        </div>
    )
}