import BG from "./../assets/cover/BG.jpg";
// import BG from "./../assets/cover/tmp.jpg";

import ButtonStart from "./../assets/cover/button-start.png";
import Circle from "./../assets/cover/circle.png";
import IceAndStars from "./../assets/cover/ice-and-stars.png";
import Stars from "./../assets/cover/stars.png";
import SmallIceLeft from "./../assets/cover/small-ice-left.png";
import SmallIceRight from "./../assets/cover/small-ice-right.png";
import FruitApple from "./../assets/cover/fruit-apple.png";
import FruitCherry from "./../assets/cover/fruit-cherry.png";
import FruitPear from "./../assets/cover/fruit-pear.png";
import Meteor1 from "./../assets/cover/meteor-1.png";
import Meteor2 from "./../assets/cover/meteor-2.png";
import Meteor3 from "./../assets/cover/meteor-3.png";
import Title from "./../assets/cover/title.png";
import TitleStar1 from "./../assets/cover/title-star-1.png";
import TitleStar2 from "./../assets/cover/title-star-2.png";
import Text from "./../assets/cover/text.png";
import Fye from "./../assets/cover/fye.png";

export const data = {
    mainText: '飛飛快速地敲開宇宙中的冰凍食物，\n將食物分送給等待著的動物朋友。',
    mainTextDelay: 2600,
    backgroundUrl: BG,
    startButton: {
        url: ButtonStart,
        width: '17.5%',
        x: '50%',
        y: '68.3%',
    },
    assets: [
        {
            url: Circle,
            width: "78.4%",
            x: "51.1%",
            y: "60%",
            animeProperties: {
                opacity: [0.1, 1],
                scale: [1.2, 1],
                duration: 2000,
                easing: 'cubicBezier(0, 0, 0, 1)'
            },
        },
        {
            url: IceAndStars,
            width: '100%',
            x: '50%',
            y: '50%',
        },
        {
            url: Stars,
            width: '56.7%',
            x: '50.6%',
            y: '54.5%',
        },
        {
            url: SmallIceLeft,
            width: "5.7%",
            x: "13.6%",
            y: "79.3%",
        },
        {
            url: SmallIceRight,
            width: "7.6%",
            x: "92.8%",
            y: "62.2%",
        },
        {
            url: FruitApple,
            width: '19%',
            x: '30%',
            y: '56%',
            animeProperties: {
                translateY: ['2%', '-2%'],
                duration: 1800,
                direction: 'alternate',
                loop: true,
                easing: 'easeInOutSine',
                translateZ: 0,
            },
        },
        {
            url: FruitCherry,
            width: '18.5%',
            x: '70.55%',
            y: '71.1%',
            animeProperties: {
                translateY: ['-2%', '2%'],
                duration: 1800,
                direction: 'alternate',
                loop: true,
                easing: 'easeInOutSine',
                translateZ: 0,
            },
        },
        {
            url: FruitPear,
            width: '12.3%',
            x: '71.7%',
            y: '42.3%',
            animeProperties: {
                translateY: ['2%', '-2%'],
                duration: 2400,
                direction: 'alternate',
                loop: true,
                easing: 'easeInOutSine',
                translateZ: 0,
            },
        },
        {
            url: Meteor1,
            width: "11.5%",
            x: "28.1%",
            y: "16.7%",
            animeProperties: {
                translateX: ['150%', 0],
                translateY: ['200%', 0],
                opacity: [0, 1],
                duration: 450,
                delay: 1800,
                easing: 'easeInOutSine',
            },
        },
        {
            url: Meteor2,
            width: "8.4%",
            x: "19.9%",
            y: "72%",
            animeProperties: {
                translateX: ['120%', 0],
                translateY: ['160%', 0],
                opacity: [0, 1],
                duration: 450,
                delay: 1900,
                easing: 'easeInOutSine',
            },
        },
        {
            url: Meteor3,
            width: "6.7%",
            x: "90.2%",
            y: "45.3%",
            animeProperties: {
                translateX: ['170%', 0],
                translateY: ['160%', 0],
                opacity: [0, 1],
                duration: 450,
                delay: 2000,
                easing: 'easeInOutSine',
            },
        },
        {
            url: Title,
            width: '37.5%',
            x: '50%',
            y: '30.9%',
        },
        {
            url: TitleStar1,
            width: '2.6%',
            x: '33.5%',
            y: '35.1%',
            animeProperties: {
                opacity: [0, 1],
                duration: 400,
                direction: 'alternate',
                loop: true,
                easing: 'spring(1, 80, 10, 0)',
            },
        },
        {
            url: TitleStar2,
            width: "2.9%",
            x: "60.4%",
            y: "24.6%",
            animeProperties: {
                opacity: [0, 1],
                duration: 400,
                delay: 100,
                direction: 'alternate',
                loop: true,
                easing: 'spring(1, 80, 10, 0)',
            },
        },
        {
            url: Text,
            width: '22.9%',
            x: '50%',
            y: '77.9%',
            hideOnMobile: true,
            animeProperties: {
                opacity: [0, 1],
                delay: 2600,
                duration: 800,
                easing: 'easeInOutSine',
            },
        },
        {
            url: Fye,
            width: '23.2%',
            x: '52.9%',
            y: '50.8%',
        },
    ]
};