import EndingBG from "./../assets/modal/ending-BG.jpg";
// import EndingBG from "./../assets/modal/tmp-success.jpg";
// import EndingBG from "./../assets/modal/tmp-failure.jpg";
import Wrapper from "./../assets/modal/wrapper.png";
import Wrapper_2 from "./../assets/modal/wrapper-2.png";
import WrapperTitle from "./../assets/modal/wrapper-title.png";
import WrapperText from "./../assets/modal/wrapper-text.png";
import WrapperFinishedPuzzling from "./../assets/modal/wrapper-finished-puzzling.png";
import WrapperButtonStart from "./../assets/modal/wrapper-button-start.png";
import WrapperButtonContinue from "./../assets/modal/wrapper-button-continue.png";
import WrapperButtonGoNextPage from "./../assets/modal/wrapper-button-go-next-page.png";
import WrapperButtonRestart from "./../assets/modal/wrapper-button-restart.png";
import WrapperButtonSeeFinished from "./../assets/modal/wrapper-button-see-finished.png";

import ButtonRestart from "./../assets/modal/button-restart.png";
import ButtonGoNextPage from "./../assets/modal/button-go-next-page.png";

import TextSuccess from "./../assets/modal/text-success.png";
import SuccessAnimals from "./../assets/modal/success-animals.png";
import SuccessLittleText from "./../assets/modal/success-little-text.png";
import SuccessStarLeft from "./../assets/modal/success-star-left.png";
import SuccessStarRight from "./../assets/modal/success-star-right.png";

import TextFailure from "./../assets/modal/text-failure.png";
import FailureAnimals from "./../assets/modal/failure-animals.png";

import EndingMeteorLeft from "./../assets/modal/ending-meteor-left.png";
import EndingMeteorRight from "./../assets/modal/ending-meteor-right.png";

const FunctionType = {
    CloseModal: "CloseMoal",
    RestartGame: "RestartGame",
    BackToStory: "BackToStory",
    OpenLandingFinished: "OpenLandingFinished",
    OpenPausingFinished: "OpenPausingFinished",
};

export const data = {
    Landing: {
        backgroundUrl: null,
        buttons: [
            {
                functionType: FunctionType.OpenLandingFinished,
                url: WrapperButtonSeeFinished,
                width: '16.4%',
                x: '50.4%',
                y: '60.4%',
            }
        ],
        assets: [
            {
                url: Wrapper,
                width: '45%',
                x: '50.2%',
                y: '53.1%',
            },
            {
                url: WrapperTitle,
                width: '35.4%',
                x: '50.3%',
                y: '38%',
            },
            {
                url: WrapperText,
                width: '31.2%',
                x: '51.3%',
                y: '50.5%',
            }
        ]
    },
    LandingFinished: {
        backgroundUrl: null,
        buttons: [
            {
                functionType: FunctionType.CloseModal,
                url: WrapperButtonStart,
                width: '16.5%',
                x: '50.5%',
                y: '66.7%',
            }
        ],
        assets: [
            {
                url: Wrapper_2,
                width: '44.7%',
                x: '50.2%',
                y: '51%',
            },
            {
                url: WrapperTitle,
                width: '35.4%',
                x: '50.3%',
                y: '29.8%',
            },
            {
                url: WrapperFinishedPuzzling,
                width: '23.3%',
                x: '50.7%',
                y: '49.1%',
            }
        ]
    },
    Pausing: {
        backgroundUrl: null,
        buttons: [
            {
                functionType: FunctionType.CloseModal,
                url: WrapperButtonContinue,
                width: '15.8%',
                x: '40.7%',
                y: '54.9%',
            },
            {
                functionType: FunctionType.OpenPausingFinished,
                url: WrapperButtonSeeFinished,
                width: '16.5%',
                x: '59.5%',
                y: '54.8%',
            },
            {
                functionType: FunctionType.RestartGame,
                url: WrapperButtonRestart,
                width: '16.4%',
                x: '41%',
                y: '62.55%',
            },
            {
                functionType: FunctionType.BackToStory,
                url: WrapperButtonGoNextPage,
                width: '16.5%',
                x: '59.4%',
                y: '62.45%',
            }
        ],
        assets: [
            {
                url: Wrapper,
                width: '49.4%',
                x: '50%',
                y: '52.7%',
            },
            {
                url: WrapperTitle,
                width: '32.4%',
                x: '49.7%',
                y: '33.7%',
            },
            {
                url: WrapperText,
                width: '31.2%',
                x: '50.7%',
                y: '45.1%',
            }
        ]
    },
    PausingFinished: {
        backgroundUrl: null,
        buttons: [
            {
                functionType: FunctionType.CloseModal,
                url: WrapperButtonContinue,
                width: '16.5%',
                x: '50.4%',
                y: '66.6%',
            }
        ],
        assets: [
            {
                url: Wrapper_2,
                width: '44.7%',
                x: '50.2%',
                y: '51%',
            },
            {
                url: WrapperTitle,
                width: '35.4%',
                x: '50.3%',
                y: '29.8%',
            },
            {
                url: WrapperFinishedPuzzling,
                width: '23.3%',
                x: '50.7%',
                y: '49.1%',
            }
        ]
    },
    Failure: {
        backgroundUrl: EndingBG,
        buttons: [
            {
                functionType: FunctionType.RestartGame,
                url: ButtonRestart,
                width: '13.9%',
                x: '50.7%',
                y: '67.4%',
            },
            {
                functionType: FunctionType.BackToStory,
                url: ButtonGoNextPage,
                width: '14.1%',
                x: '50.7%',
                y: '76.2%',
            }
        ],
        assets: [
            {
                url: TextFailure,
                width: '36.2%',
                x: '49.2%',
                y: '25.6%',
            },
            {
                url: FailureAnimals,
                width: '52.2%',
                x: '50.8%',
                y: '47.7%',
            },
            {
                url: EndingMeteorLeft,
                width: '14%',
                x: '25%',
                y: '16.9%',
            },
            {
                url: EndingMeteorRight,
                width: '10.5%',
                x: '82.6%',
                y: '42.1%',
            }
        ]
    },
    Success: {
        backgroundUrl: EndingBG,
        buttons: [
            {
                functionType: FunctionType.RestartGame,
                url: ButtonRestart,
                width: '13.9%',
                x: '50.7%',
                y: '67.4%',
            },
            {
                functionType: FunctionType.BackToStory,
                url: ButtonGoNextPage,
                width: '14.1%',
                x: '50.7%',
                y: '76.2%',
            }
        ],
        assets: [
            {
                url: TextSuccess,
                width: '20.4%',
                x: '33.1%',
                y: '33%',
                // animation: animations.SpaceFloating_1,
            },
            {
                url: SuccessAnimals,
                width: '51.7%',
                x: '54.5%',
                y: '39%',
            },
            {
                url: SuccessLittleText,
                width: '14%',
                x: '38.2%',
                y: '38.7%',
            },
            {
                url: SuccessStarLeft,
                width: '2.05%',
                x: '27.9%',
                y: '51.9%',
            },
            {
                url: SuccessStarRight,
                width: '2.4%',
                x: '54.6%',
                y: '53%',
            },
            {
                url: EndingMeteorLeft,
                width: '14%',
                x: '25%',
                y: '16.9%',
            },
            {
                url: EndingMeteorRight,
                width: '10.5%',
                x: '82.6%',
                y: '42.1%',
            }
        ]
    }
};