import BG from "./assets/BG.jpg";
// import BG from "./assets/tmp.jpg";

import Balls from "./assets/balls.png";
import FyeFootLeft from "./assets/fye-foot-left.png";
import FyeHandLeft from "./assets/fye-hand-left.png";
import FyeMainBody from "./assets/fye-main-body.png";
import FishTankClean from "./assets/fish-tank-clean.png";
import FishTankDirty from "./assets/fish-tank-dirty.png";
import FyeFootRight from "./assets/fye-foot-right.png";
import FyeHandRight from "./assets/fye-hand-right.png";
import Lines from "./assets/lines.png";
import Text from "./assets/text.png";

import StarBig from "./assets/star-big.png";
import StarBigFrontGlowing from "./assets/star-big-front-glowing.png";
import StarBigGlowing from "./assets/star-big-glowing.png";
import StarBigSparkling from "./assets/star-big-sparkling.png"
import StarSmall from "./assets/star-small.png";
import StarSmallFrontGlowing from "./assets/star-small-front-glowing.png";
import StarSmallGlowing from "./assets/star-small-glowing.png";
import StarSmallSparkling from "./assets/star-small-sparkling.png";
import { data as marketingData } from "./../../marketing/index.js";

import animations from "./../../assets/Animation.module.scss";

export const data = {
    barComponent: null,
    mainText: '飛行中，魚缸越來越霧，\n路開始看不清楚，\n飛飛跌落數字黑洞。',
    mainTextDelay: 2800,
    backgroundUrl: BG,
    assets: [
        {
            url: Balls,
            width: '66%',
            x: '63%',
            y: '47.1%',
            animeProperties: {
                opacity: [0, 1],
                translateX: ['5%', 0],
                translateY: ['-4%', 0],
                // delay: 2000,
                duration: 2800,
                // loop: true,
                // direction: 'alternate',
                easing: 'easeOutCubic'
            },
        },
        {
            url: Lines,
            width: '35%',
            x: '17.5%',
            y: '67.2%',
            // animeProperties: {
            //     opacity: [0.5, 1],
            //     scale: [1, 1.2],
            //     translateX: ['40%', '0'],
            //     translateY: ['-8%', '0'],
            //     // delay: 2000,
            //     duration: 5000,
            //     loop: true,
            //     direction: 'alternate',
            //     easing: 'easeInOutSine'
            // },
        },
        {
            url: FyeFootLeft,
            width: '22.7%',
            x: '47.4%',
            y: '71.5%',
            // animeProperties: {
            //     transformOrigin: '100%',
            //     rotate: ['-8deg', '3deg'],
            //     translateY: ['20%', '0'],
            //     duration: 3000,
            //     direction: 'alternate',
            //     loop: true,
            //     easing: 'easeInOutSine'
            // },
        },
        {
            url: FyeHandLeft,
            width: '7.6%',
            x: '54.5%',
            y: '28.1%',
            animeProperties: {
                transformOrigin: '100%',
                rotate: ['-3deg', '3deg'],
                translateY: ['20%', '-10%'],
                translateX: ['-20%', '10%'],
                duration: 3000,
                direction: 'alternate',
                loop: true,
                easing: 'easeInOutSine'
            },
        },
        {
            url: FyeMainBody,
            width: '26.2%',
            x: '64.25%',
            y: '51.75%',
        },
        {
            url: FishTankClean,
            width: '27%',
            x: '64.7%',
            y: '42.8%',
            animeProperties: {
                opacity: [1, 0],
                delay: 1400,
                duration: 1200,
                easing: 'easeInOutSine'
            },
        },
        {
            url: FishTankDirty,
            width: '25.5%',
            x: '65.1%',
            y: '41.3%',
            animeProperties: {
                opacity: [0, 1],
                delay: 1400,
                duration: 1200,
                easing: 'easeInOutSine'
            },
        },
        {
            url: FyeFootRight,
            width: '31.1%',
            x: '51.8%',
            y: '79.7%',
            // animeProperties: {
            //     transformOrigin: '100%',
            //     rotate: ['-3deg', '3deg'],
            //     translateY: ['2%', '0%'],
            //     duration: 4000,
            //     direction: 'alternate',
            //     loop: true,
            //     easing: 'easeInOutSine'
            // },
        },
        {
            url: FyeHandRight,
            width: '24%',
            x: '48.95%',
            y: '62.85%',
            animeProperties: {
                // transformOrigin: '100%',
                rotate: ['5deg', '-5deg'],
                translateY: ['-7%', '8%'],
                duration: 3000,
                direction: 'alternate',
                loop: true,
                easing: 'linear'
            },
        },
        {
            url: Text,
            width: '23.9%',
            x: '38.8%',
            y: '40.6%',
            hideOnMobile: true,
            animeProperties: {
                opacity: [0, 1],
                delay: 2800,
                duration: 800,
                easing: 'easeInOutSine'
            },
        },
    ],
    marketings: [
        {
            id: 1,
            url: StarSmall,
            width: '7.5%',
            x: '31.4%',
            y: '70.6%',
            content: marketingData[1],
            attaches: [
                {
                    url: StarSmallGlowing,
                    width: '84%',
                    x: '51%',
                    y: '51%',
                    zIndex: -1,
                    animation: animations.Glowing,
                },
                {
                    url: StarSmallSparkling,
                    width: '133%',
                    x: '51%',
                    y: '51%',
                    zIndex: -2,
                    animation: animations.Sparkling,
                },
                {
                    url: StarSmallFrontGlowing,
                    width: '59%',
                    x: '51%',
                    y: '52%',
                    animation: animations.Glowing,
                }
            ]
        },
        {
            id: 2,
            url: StarBig,
            width: '11.5%',
            x: '27.2%',
            y: '57.7%',
            content: marketingData[2],
            attaches: [
                {
                    url: StarBigGlowing,
                    width: '71%',
                    x: '54%',
                    y: '51.7%',
                    zIndex: -1,
                    animation: animations.Glowing_2,
                },
                {
                    url: StarBigSparkling,
                    width: '115%',
                    x: '51%',
                    y: '49%',
                    zIndex: -2,
                    animation: animations.Sparkling_2,
                },
                {
                    url: StarBigFrontGlowing,
                    width: '44%',
                    x: '51%',
                    y: '52%',
                    animation: animations.Glowing_2,
                }
            ]
        },
    ],
};