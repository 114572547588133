import BG from "./assets/BG.jpg";
// import BG from "./assets/tmp.jpg";

import Stars from "./assets/stars.png";
import CloudTop from "./assets/cloud-top.png";
import CloudBottom from "./assets/cloud-bottom.png";
import Fruits from "./assets/fruits.png";
import WhiteLine from "./assets/white-line.png";
import Fye from "./assets/fye.png";
import Text from "./assets/text.png";
import HandWriteText from "./assets/hand-write-text.png";

export const data = {
    barComponent: null,
    mainText: '飛飛忙著分送食物，再匆匆離開，\n心想......\n我是宇宙中唯一的英雄！',
    mainTextDelay: 1500,
    backgroundUrl: BG,
    assets: [
        {
            url: Stars,
            width: '87.9%',
            x: '43.9%',
            y: '54.1%',
            // animeProperties: {
            //     opacity: [0, 1],
            //     duration: 3000,
            //     direction: 'alternate',
            //     loop: true,
            //     easing: 'easeInOutSine'
            // },
        },
        {
            url: CloudTop,
            width: '38%',
            x: '81%',
            y: '25.1%',
            animeProperties: {
                translateY: ['-8%', '0'],
                translateX: ['8%', '0'],
                opacity: [0.9, 1],
                duration: 3000,
                easing: 'easeInOutSine',
                translateZ: 0,
            },
        },
        {
            url: CloudBottom,
            width: '69%',
            x: '34.9%',
            y: '75.2%',
            animeProperties: {
                translateY: ['8%', '0'],
                translateX: ['-8%', '0'],
                opacity: [0.9, 1],
                duration: 3000,
                easing: 'easeInOutSine',
                translateZ: 0,
            },
        },
        {
            url: HandWriteText,
            width: '33.5%',
            x: '42%',
            y: '58.8%',
            animeProperties: {
                opacity: [0, 1],
                delay: 4100,
                duration: 800,
                easing: 'easeInOutSine'
            },
        },
        {
            url: Fruits,
            width: '16.3%',
            x: '65.9%',
            y: '66.2%',
        },
        {
            url: WhiteLine,
            width: '27.1%',
            x: '27.4%',
            y: '21.9%',
        },
        {
            url: Text,
            width: '35.4%',
            x: '40.5%',
            y: '36.55%',
            hideOnMobile: true,
            animeProperties: {
                opacity: [0, 1],
                delay: 2800,
                duration: 800,
                easing: 'easeInOutSine'
            },
        },
        {
            url: Fye,
            width: '33.2%',
            x: '60%',
            y: '36%',
            animeProperties: {
                translateY: ['60%', '0'],
                translateX: ['60%', '0'],
                duration: 3000,
                easing: 'easeOutQuart',
                translateZ: 0,
            },
        },
    ]
};