import React, { useState, useRef } from 'react';
import ReactModal from 'react-modal';
import { CrossSvg } from '../../../../assets/CrossSvg.jsx';
import { ArrowLeftSvg } from '../../../../assets/ArrowLeftSvg.jsx';
import SoundButton from "../../../../../sound-button/SoundButton.jsx";
import "./Animation.scss";
import "./CustomModal.scss";

import About from "./../assets/about.jpg";
import TextAbout from "./../assets/text-about.png";
import Section_1 from "./../assets/section-1.png";
import Section_2 from "./../assets/section-2.png";
import Section_3 from "./../assets/section-3.png";
import ButtonEarlyService from "./../assets/button-early-service.png";
import ButtonElephant from "./../assets/button-elephant.png";
import EarlyService from "./../assets/early-service.png";
import EarlyServiceText from "./../assets/early-service-text.png";
import Elephant from "./../assets/elephant.png";
import ElephantText from "./../assets/elephant-text.png";
import AboutMB from "./../assets/about-mb.png";
import EarlyServiceMB from "./../assets/early-service-mb.png";
import ElephantMB from "./../assets/elephant-mb.png";

import styles from "./Modal.module.scss";
import styles_Basic from "./../../../../../ScenceBasicElement.module.scss";
import anime from "animejs";

const aboutAssets = [
    {
        url: TextAbout,
        width: '15%',
        x: '50%',
        y: '5.6%',
    },
    {
        url: Section_1,
        width: '93%',
        x: '46.5%',
        y: '22.2%',
    },
    {
        url: Section_2,
        width: '90.5%',
        x: '54.73%',
        y: '50.6%',
    },
    {
        url: Section_3,
        width: '91%',
        x: '47.2%',
        y: '76.2%',
    }
]

const Slug = {
    About: "About",
    Elephant: "Elephant",
    EarlyService: "EarlyService",
}

const Mode = {
    HorizontalMode: "HorizontalMode",
    VerticalMode: "VerticalMode",
    MobileMode: "MobileMode"
}

export const Modal = props => {
    const [displaySlug, setDisplaySlug] = useState(Slug.About);
    const scenceRef = useRef(null);

    const changePage = (newSlug) => {
        anime({
            targets: scenceRef.current,
            opacity: [1, 0],
            duration: 400,
            direction: 'alternate',
            easing: 'easeInOutSine'
        });
        setTimeout(() => {
            setDisplaySlug(newSlug);
        }, 400);
    }

    return (
        <>
            <ReactModal
                // id="test2"
                isOpen={true}
                closeTimeoutMS={300}
                style={{
                    ...customStyles,
                    overlay: {
                        ...customStyles.overlay,
                        zIndex: props.isOpen ? '1' : '-1',
                        opacity: props.isOpen ? '1' : '0',
                        transition: props.isOpen ? 
                            'opacity 0.3s ease-in-out' : 
                            'z-index 0.3s step-end, opacity 0.3s ease-in-out'
                    },
                    content: {
                        ...customStyles.content,
                        width: props.interfaceMode===Mode.HorizontalMode ? `calc(133.3333vh - 26.6vh)` : props.interfaceMode===Mode.MobileMode ? 'calc(100vw - 16px)' : 'calc(100vw - 20%)',
                        // left: props.interfaceMode===Mode.HorizontalMode ? `calc(calc(calc(100vw - 133.3333vh)/2) + 13.3vh)` : props.interfaceMode===Mode.MobileMode ? '8px' : '10%',
                        // right: props.interfaceMode===Mode.HorizontalMode ? `calc(calc(calc(100vw - 133.3333vh)/2) + 13.3vh)` : props.interfaceMode===Mode.MobileMode ? '8px' : '10%',
                        top: props.interfaceMode===Mode.VerticalMode ? `calc(calc(calc(100vh - 75vw)/2) + 7.5vw)` : props.interfaceMode===Mode.MobileMode ? '50%' : '10%',
                        bottom: props.interfaceMode===Mode.VerticalMode ? `calc(calc(calc(100vh - 75vw)/2) + 7.5vw)` : props.interfaceMode===Mode.MobileMode ? 'initial' : '10%',
                        maxWidth: props.interfaceMode===Mode.MobileMode ? '400px' : 'initial',
                        maxHeight: props.interfaceMode===Mode.MobileMode ? 'calc(100% - 16px)' : 'initial',
                        borderRadius: props.interfaceMode===Mode.MobileMode ? '8px' : '32px',
                        transform: props.interfaceMode===Mode.MobileMode ? 'translate(-50%, -50%)' : 'translate(-50%, 0)',
                    }
                }}
                parentSelector={() => document.body}
                ariaHideApp={false}
            >
                <div ref={scenceRef} style={{ height: '100%' }}>
                    <div className={`${styles.Page} ${displaySlug===Slug.About ? styles.Active : ''}`}>
                        <img src={props.interfaceMode!==Mode.MobileMode ? About : AboutMB} alt="" />
                        {props.interfaceMode!==Mode.MobileMode && aboutAssets.map((asset, index) => 
                            <div 
                                key={index}
                                className={styles_Basic.AssetWrapper}
                                style={{
                                    width: asset.width,
                                    left: asset.x,
                                    top: asset.y,
                                }}
                            >
                                <img src={asset.url} draggable={false} />
                            </div>
                        )}
                        <SoundButton
                            className={`${styles_Basic.AssetWrapper} ${styles_Basic.Button}`} 
                            style={
                                props.interfaceMode!==Mode.MobileMode ? 
                                    { width: '40.5%', top: '93.2%', left: '30%' } : 
                                    { width: '60.5%', top: '87.5%', left: '50%' }
                            }
                            onClick={() => changePage(Slug.EarlyService)}
                        >
                            <img src={ButtonEarlyService} alt=""/>
                        </SoundButton>
                        <SoundButton 
                            className={`${styles_Basic.AssetWrapper} ${styles_Basic.Button}`} 
                            style={
                                props.interfaceMode!==Mode.MobileMode ? 
                                    { width: '40.5%', top: '93.2%', left: '72.5%' } :
                                    { width: '60.5%', top: '93.7%', left: '50%' }
                                }
                            onClick={() => changePage(Slug.Elephant)}
                        >
                            <img src={ButtonElephant} alt=""/>
                        </SoundButton>
                    </div>
                    <div className={`${styles.Page} ${displaySlug===Slug.Elephant ? styles.Active : ''}`}>
                        <img src={props.interfaceMode!==Mode.MobileMode ? Elephant : ElephantMB} alt="" />
                        {props.interfaceMode!==Mode.MobileMode ?
                            <div 
                                className={styles_Basic.AssetWrapper}
                                style={{
                                    width: '57%',
                                    left: '51%',
                                    top: '55%',
                                }}
                            >
                                <img src={ElephantText} draggable={false} />
                            </div> : ''
                        }
                    </div>
                    <div className={`${styles.Page} ${displaySlug===Slug.EarlyService ? styles.Active : ''}`}>
                        <img src={props.interfaceMode!==Mode.MobileMode ? EarlyService : EarlyServiceMB} alt="" />
                        {props.interfaceMode!==Mode.MobileMode ?
                            <div 
                                className={styles_Basic.AssetWrapper}
                                style={{
                                    width: '63%',
                                    left: '51%',
                                    top: '59.5%',
                                }}
                            >
                                <img src={EarlyServiceText} draggable={false} />
                            </div> : ''
                        }
                    </div>
                </div>
            </ReactModal>
            <ReactModal
                isOpen={true}
                closeTimeoutMS={300}
                style={{
                    ...buttonsModalStyles,
                    overlay: {
                        ...buttonsModalStyles.overlay,
                        zIndex: props.isOpen ? '1' : '-1',
                        opacity: props.isOpen ? '1' : '0',
                        transition: props.isOpen ? 
                            'opacity 0.3s ease-in-out' : 
                            'z-index 0.3s step-end, opacity 0.3s ease-in-out',
                    },
                    content: {
                        ...buttonsModalStyles.content,
                        width: props.interfaceMode===Mode.HorizontalMode ? `calc(133.3333vh - 26.6vh)` : props.interfaceMode===Mode.MobileMode ? 'calc(100vw - 16px)' : 'calc(100vw - 20%)',
                        // left: props.interfaceMode===Mode.HorizontalMode ? `calc(calc(calc(100vw - 133.3333vh)/2) + 13.3vh)` : props.interfaceMode===Mode.MobileMode ? '8px' : '10%',
                        // right: props.interfaceMode===Mode.HorizontalMode ? `calc(calc(calc(100vw - 133.3333vh)/2) + 13.3vh)` : props.interfaceMode===Mode.MobileMode ? '8px' : '10%',
                        top: props.interfaceMode===Mode.VerticalMode ? `calc(calc(calc(100vh - 75vw)/2) + 7.5vw)` : props.interfaceMode===Mode.MobileMode ? '50%' : '10%',
                        bottom: props.interfaceMode===Mode.VerticalMode ? `calc(calc(calc(100vh - 75vw)/2) + 7.5vw)` : props.interfaceMode===Mode.MobileMode ? 'initial' : '10%',
                        maxWidth: props.interfaceMode===Mode.MobileMode ? '400px' : 'initial',
                        maxHeight: props.interfaceMode===Mode.MobileMode ? 'calc(100% - 16px)' : 'initial',
                        borderRadius: props.interfaceMode===Mode.MobileMode ? '8px' : '32px',
                        transform: props.interfaceMode===Mode.MobileMode ? 'translate(-50%, -50%)' : 'translate(-50%, 0)',
                    }
                }}
                parentSelector={() => document.body}
                ariaHideApp={false}
            >
                <div style={{ height: '100%' }}>
                    {(displaySlug===Slug.Elephant || displaySlug===Slug.EarlyService) ?
                        <SoundButton
                            className={styles.BackButton} 
                            onClick={() => changePage(Slug.About)} 
                            style={{ opacity: 1 }}
                        >
                            <ArrowLeftSvg />
                        </SoundButton> : ''}
                    <SoundButton 
                        className={styles.CloseButton} 
                        style={{ opacity: 1 }}
                        onClick={() => {
                            changePage(Slug.About);
                            props.closeModal();
                        }}
                    >
                        <CrossSvg />
                    </SoundButton>
                    <div className={`${styles.Page} ${displaySlug===Slug.About ? styles.Active : ''}`}>
                        <img style={{ opacity: 0 }} src={props.interfaceMode!==Mode.MobileMode ? About : AboutMB} alt="" />
                    </div>
                    <div className={`${styles.Page} ${displaySlug===Slug.Elephant ? styles.Active : ''}`}>
                        <img style={{ opacity: 0 }} src={props.interfaceMode!==Mode.MobileMode ? Elephant : ElephantMB} alt="" />
                    </div>
                    <div className={`${styles.Page} ${displaySlug===Slug.EarlyService ? styles.Active : ''}`}>
                        <img style={{ opacity: 0 }} src={props.interfaceMode!==Mode.MobileMode ? EarlyService : EarlyServiceMB} alt="" />
                    </div>
                </div>
            </ReactModal>
        </>
    )
}

const customStyles = {
    overlay: {
        position: "absolute",
        backgroundColor: 'rgba(4, 12, 64, 0.85)',
    },
    content: {
        top: '10%',
        left: '50%',
        // right: '10%',
        // transform: 'translate(-50%, 0)',
        bottom: '10%',
        display: 'block',
        margin: 'auto',
        padding: '0',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        border: 'none',
        borderRadius: '32px',
        boxShadow: 'rgba(255, 255, 255, 0.35) 0px 0px 50px 12px',
    }
};

const buttonsModalStyles = {
    overlay: {
        position: "absolute",
        backgroundColor: 'rgba(0, 0, 0, 0)',
        pointerEvents: 'none',
    },
    content: {
        top: '10%',
        left: '50%',
        // right: '10%',
        bottom: '10%',
        margin: 'auto',
        padding: '0',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        border: 'none',
        borderRadius: '0',
        pointerEvents: 'none',
        // boxShadow: 'rgba(255, 255, 255, 0.35) 0px 0px 50px 12px',
    }
};