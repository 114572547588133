import React, { useState, useEffect, useRef, useMemo } from "react";
import { Modal } from "./../modal-marketing/Modal.jsx";
import { LazyImage } from "../../LazyImage.jsx";
import MainText from "./../../MainText.jsx";
import SoundButton from "./../../sound-button/SoundButton.jsx";
import badge from "./../assets/badge.png";
import styles_Interface from "../../Interface.module.scss";
import styles_Basic from "./../../ScenceBasicElement.module.scss";
import styles from "./Result.module.scss";
import anime from "animejs";

const Mode = {
    HorizontalMode: "HorizontalMode",
    VerticalMode: "VerticalMode",
    MobileMode: "MobileMode"
}

const Result = props => {
    const [modalOpened, setModalOpened] = useState(false);
    const [activeMarketingIndex, setActiveMarketingIndex] = useState('');
    const scenceRef = useRef(null);
    let assetsRef = [];

    useEffect(() => {
        if(props.isActive) {
            assetsRef.map((assetRef, index) => {
                const assetData = props.data.assets[index];
                if(assetData.animeProperties) {
                    assetRef.style.transform = "";
                    setTimeout(() => {
                        anime({ 
                            targets: assetRef, 
                            ...assetData.animeProperties 
                        });
                    }, 0);
                }
            });
        } else anime.remove(assetsRef)
    }, [props.isActive]);

    const openModal = (marketingIndex) => {
        props.handleSectionSwitcherLocked(true);
        setActiveMarketingIndex(marketingIndex);
        setModalOpened(true);

        let preState = [...props.marketingsState];
        preState[marketingIndex] = true;
        setTimeout(() => props.setMarketingsState([...preState]), 1000);
    }

    const closeModal = () => {
        props.handleSectionSwitcherLocked(false);
        setModalOpened(false);
    }

    
    // return useMemo(() => {
        // console.log('result page rendered');
    return (
        <>
            <div 
                className={
                    `${styles_Interface[props.interfaceMode]}`
                    + ` ${styles.Wrapper}`
                }
                ref={scenceRef}
            >
                <div className={styles_Basic.BackgroundWrapper}>
                    <LazyImage
                        loadLazyImage={props.loadLazyImage}
                        src={props.data.backgroundUrl}
                        attributes={{
                            className: styles_Basic.BackgroundImg,
                            draggable: false,
                            alt: ""
                        }}
                    />
                    {props.data.assets.map((asset, index) => 
                        <div 
                            key={index}
                            className={styles_Basic.AssetWrapper}
                            style={{
                                width: asset.width,
                                opacity: 1,
                                left: asset.x,
                                userSelect: 'none',
                                top: asset.y,
                                display: asset.hideOnMobile && props.interfaceMode===Mode.MobileMode ? 'none' : 'block',
                                zIndex: asset.zIndex || '2'
                            }}
                        >
                            <LazyImage
                                loadLazyImage={props.loadLazyImage}
                                ref={ref => assetsRef[index] = ref}
                                src={asset.url}
                                attributes={{
                                    draggable: false,
                                    alt: ""
                                }}
                            />
                        </div>
                    )}

                    {props.data.games.map((asset, index) => 
                        <SoundButton 
                            key={index}
                            className={`${styles.Game}`}
                            style={{
                                width: asset.width,
                                opacity: 1,
                                left: asset.x,
                                userSelect: 'none',
                                top: asset.y,
                            }}
                            onClick={() => window.fullpage_api.moveTo(asset.gamePageIndex)}
                        >
                            <>
                                <LazyImage
                                    loadLazyImage={props.loadLazyImage}
                                    src={asset.urls[1]}
                                    attributes={{
                                        className: props.gamesState[index] ? styles.Active : '',
                                        draggable: false,
                                        alt: ""
                                    }}
                                />
                                <LazyImage
                                    loadLazyImage={props.loadLazyImage}
                                    src={asset.urls[0]}
                                    attributes={{
                                        className: !props.gamesState[index] ? styles.Active : '',
                                        draggable: false,
                                        alt: ""
                                    }}
                                />
                                {props.gamesState[index] ?
                                    <img 
                                        className={styles.Badge} 
                                        style={{
                                            width: asset.badge.width,
                                            opacity: 1,
                                            left: asset.badge.x,
                                            userSelect: 'none',
                                            top: asset.badge.y,
                                        }}
                                        src={badge} 
                                        alt="" 
                                    /> : ''
                                }
                            </>
                        </SoundButton>
                    )}

                    {props.data.marketings.map((asset, index) => 
                        <SoundButton 
                            key={index}
                            className={`${styles.Marketing} ${styles_Basic.Button}`}
                            style={{
                                width: asset.width,
                                opacity: 1,
                                left: asset.x,
                                userSelect: 'none',
                                top: asset.y,
                            }}
                            onClick={() => openModal(index)}
                        >
                            <>
                                <LazyImage
                                    loadLazyImage={props.loadLazyImage}
                                    src={asset.urls[1]}
                                    attributes={{
                                        className: props.marketingsState[index] ? styles.Active : '',
                                        draggable: false,
                                        alt: ""
                                    }}
                                />
                                <LazyImage
                                    loadLazyImage={props.loadLazyImage}
                                    src={asset.urls[0]}
                                    attributes={{
                                        className: !props.marketingsState[index] ? styles.Active : '',
                                        draggable: false,
                                        alt: ""
                                    }}
                                />
                            </>
                        </SoundButton>
                    )}
                    {/* <div className={`${styles_Basic.ModalShadow} ${modalOpened ? styles_Basic.Active : ''}`} />  */}
                    {props.data.marketings && props.data.marketings.map((asset, index) => 
                        <Modal
                            key={index}
                            loadLazyImage={props.loadLazyImage}
                            parent={scenceRef.current}
                            isOpen={activeMarketingIndex===index && modalOpened}
                            closeModal={closeModal}
                            content={asset.content}
                            interfaceMode={props.interfaceMode}
                        />
                    )}
                </div>
            </div>
            <MainText 
                interfaceMode={props.interfaceMode} 
                text={props.data.mainText} 
                isActive={props.isActive} 
                delay={props.data.mainTextDelay}
            />
        </>
    )
    // }, [props.isActive, props.interfaceMode, props.gamesState, props.marketingsState, modalOpened, activeMarketingIndex]);
}

Result.displayName = 'Result';

export default Result;