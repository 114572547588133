
import React from "react";
import styles from "./Bigger.module.scss";

import Text_1 from "./assets/text-1-bigger.png";
import Text_2 from "./assets/text-2-bigger.png";
import Text_3 from "./assets/text-3-bigger.png";
import LogoEden from "./assets/logo-eden.png";
import LogoCTBC from "./assets/logo-ctbc.png";
import LogoPxmart from "./assets/logo-pxmart.png";
import LogoFeib from "./assets/logo-feib.png";
import LogoRock from "./assets/logo-rock.png";
import Logo23 from "./assets/logo-23.png";
import TextInfo from "./assets/text-info-bigger.png";

const Bigger = () => 
    <div className={styles.InnerWrapper}>
        <div className={styles.Top}>
            <div className={`${styles.ImageWrapper} ${styles.Text}`}>
                <img src={Text_1} alt="" />
            </div>
            <div className={styles.ImageWrapper}>
                <img src={LogoEden} alt="" />
            </div>
            <div className={`${styles.ImageWrapper} ${styles.Text}`}>
                <img src={Text_2} alt="" />
            </div>
            <div className={styles.ImageWrapper}>
                <img src={LogoPxmart} alt="" />
            </div>
            <div className={styles.ImageWrapper}>
                <img src={LogoFeib} alt="" />
            </div>
            <div className={styles.ImageWrapper}>
                <img src={LogoRock} alt="" />
            </div>
            <div className={styles.ImageWrapper}>
                <img src={LogoCTBC} alt="" />
            </div>
            <div className={`${styles.ImageWrapper} ${styles.Text}`}>
                <img src={Text_3} alt="" />
            </div>
            <div className={styles.ImageWrapper}>
                <img src={Logo23} style={{ marginTop: '6%' }} alt="" />
            </div>
        </div>
        <div className={styles.Bottom}>
            <div className={styles.ImageWrapper}>
                <img src={TextInfo} alt="" />
            </div>
        </div>
    </div>

export default Bigger;