import React, { useEffect } from "react";
import styles from "./MainText.module.scss";
import anime from "animejs";

const Mode = {
    HorizontalMode: "HorizontalMode",
    VerticalMode: "VerticalMode",
    MobileMode: "MobileMode"
}

const MainText = props => {
    let refs = [];
    const lines = props.text.split("\n");
    
    useEffect(() => {
        if(props.isActive) {
            if(props.interfaceMode===Mode.MobileMode) {
                anime({
                    targets: refs,
                    translateY: ['100%', 0],
                    opacity: [0, 1],
                    easing: 'easeInOutSine',
                    delay: anime.stagger(1200, { start: props.delay })
                });
            }
        } else anime.remove(refs);
    }, [props.isActive]);
    
    return (
        <div className={styles[props.interfaceMode]}>
            {lines.map((line, index) => 
                <p 
                    key={index} 
                    ref={ref => refs[index] = ref}
                >{line}</p>
            )}
        </div>
    )
}

export default MainText;