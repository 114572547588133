import BG from "./../assets/game/BG.jpg";
// import BG from "./../assets/game/tmp.jpg";
// import BG from "./../assets/modal/wrapper-tmp.jpg";
// import BG from "./../assets/modal/wrapper-start-tmp.jpg";
// import BG from "./../assets/game/tmp-number.jpg";
// import BG from "./../assets/modal/wrapper-tmp-landing-1.jpg";
// import BG from "./../assets/modal/wrapper-tmp-landing-2.jpg";
// import BG from "./../assets/modal/wrapper-tmp-pausing-1.jpg";
// import BG from "./../assets/modal/wrapper-tmp-pausing-2.jpg";

import Puzzle_1 from "./../assets/game/puzzle-1.png";
import Puzzle_2 from "./../assets/game/puzzle-2.png";
import Puzzle_3 from "./../assets/game/puzzle-3.png";
import Puzzle_4 from "./../assets/game/puzzle-4.png";
import Puzzle_5 from "./../assets/game/puzzle-5.png";
import Puzzle_6 from "./../assets/game/puzzle-6.png";
import Puzzle_7 from "./../assets/game/puzzle-7.png";
import Puzzle_8 from "./../assets/game/puzzle-8.png";
import Puzzle_9 from "./../assets/game/puzzle-9.png";

import AnimalsBase from "./../assets/game/animals-base.png";
import Clouds from "./../assets/game/clouds.png";
import StarLeft from "./../assets/game/star-left.png";
import StarRight_1 from "./../assets/game/star-right-1.png";
import StarRight_2 from "./../assets/game/star-right-2.png";

export const data = {
    backgroundUrl: BG,
    targets: [
        {
            url: Puzzle_1,
            initPosition: 1
        },
        {
            url: Puzzle_2,
            initPosition: 0
        },
        {
            url: Puzzle_3,
            initPosition: 6
        },
        {
            url: Puzzle_4,
            initPosition: 8
        },
        {
            url: Puzzle_5,
            initPosition: 5
        },
        {
            url: Puzzle_6,
            initPosition: 3
        },
        {
            url: Puzzle_7,
            initPosition: 7
        },
        {
            url: Puzzle_8,
            initPosition: 4
        },
        {
            url: Puzzle_9,
            initPosition: 2
        },
    ],
    assets: [
        {
            url: AnimalsBase,
            width: '60.4%',
            x: '50%',
            y: '54.4%',
        },
        {
            url: Clouds,
            width: '100%',
            x: '50%',
            y: '20.9%',
        },
        {
            url: StarLeft,
            width: '3%',
            x: '24.6%',
            y: '74.2%',
        },
        {
            url: StarRight_1,
            width: '3%',
            x: '74.1%',
            y: '31.3%',
        },
        {
            url: StarRight_2,
            width: '2.2%',
            x: '76%',
            y: '75.7%',
        },
    ]
};