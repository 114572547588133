import BG from "./assets/BG.jpg";
// import BG from "./assets/tmp.jpg";

// import Badge from "./assets/badge.png";
import Title from "./assets/title.png";
import Text from "./assets/text.png";

import GameEmpty_1 from "./assets/game-1-empty.png";
import GameEmpty_2 from "./assets/game-2-empty.png";
import GameEmpty_3 from "./assets/game-3-empty.png";
import GameChecked_1 from "./assets/game-1-checked.png";
import GameChecked_2 from "./assets/game-2-checked.png";
import GameChecked_3 from "./assets/game-3-checked.png";
import MarketingEmpty_1 from "./assets/marketing-1-empty.png";
import MarketingEmpty_2 from "./assets/marketing-2-empty.png";
import MarketingEmpty_3 from "./assets/marketing-3-empty.png";
import MarketingEmpty_4 from "./assets/marketing-4-empty.png";
import MarketingChecked_1 from "./assets/marketing-1-checked.png";
import MarketingChecked_2 from "./assets/marketing-2-checked.png";
import MarketingChecked_3 from "./assets/marketing-3-checked.png";
import MarketingChecked_4 from "./assets/marketing-4-checked.png";
import { data as marketingData } from "./../../marketing/index.js";

import animations from "./../../../book-renderer/Animation.module.scss";
const gamePageIndex = [5 ,8, 11];

export const data = {
    barComponent: null,
    mainText: '這些閃閃發光的寶物，\n都是你在旅程中所獲得的！\n看看是不是還有沒有找到的呢？',
    mainTextDelay: 1200,
    backgroundUrl: BG,
    assets: [
        {
            url: Title,
            width: '37%',
            x: '49.3%',
            y: '24.5%',
        },
        {
            url: Text,
            width: '34.3%',
            x: '49.2%',
            y: '77.9%',
            hideOnMobile: true,
        },
    ],
    games: [
        {
            gamePageIndex: gamePageIndex[0],
            urls: [GameEmpty_1, GameChecked_1],
            width: '17.5%',
            x: '32.3%',
            y: '43.3%',
            badge: {
                width: '16.7%',
                x: '3.8%',
                y: '0',
            }
            // badge: {
            //     url: Badge,
            //     width: '2.9%',
            //     x: '25.65%',
            //     y: '38.1%',
            // }
        },
        {
            gamePageIndex: gamePageIndex[1],
            urls: [GameEmpty_2, GameChecked_2],
            width: '17.4%',
            x: '50.3%',
            y: '43.3%',
            badge: {
                width: '16.7%',
                x: '5.2%',
                y: '0',
            }
            // badge: {
            //     url: Badge,
            //     width: '2.9%',
            //     x: '25.65%',
            //     y: '38.1%',
            // }
        },
        {
            gamePageIndex: gamePageIndex[2],
            urls: [GameEmpty_3, GameChecked_3],
            width: '17.5%',
            x: '68.15%',
            y: '43.4%',
            badge: {
                width: '16.7%',
                x: '7%',
                y: '0',
            }
            // badge: {
            //     url: Badge,
            //     width: '2.95%',
            //     x: '62.05%',
            //     y: '38.05%',
            // },
        }
    ],
    marketings: [
        {
            urls: [MarketingEmpty_1, MarketingChecked_1],
            width: '9.7%',
            x: '34.3%',
            y: '63.1%',
            content: marketingData[0]
        },
        {
            urls: [MarketingEmpty_2, MarketingChecked_2],
            width: '7%',
            x: '42.7%',
            y: '63.5%',
            content: marketingData[1],
        },
        {
            urls: [MarketingEmpty_3, MarketingChecked_3],
            width: '7%',
            x: '49.5%',
            y: '64.6%',
            content: marketingData[2],
        },
        {
            urls: [MarketingEmpty_4, MarketingChecked_4],
            width: '8.2%',
            x: '57.6%',
            y: '64.9%',
            content: marketingData[3],
        },
    ]
};