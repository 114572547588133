import Story from "book-renderer/template/story/Story.jsx";
import Game from "book-renderer/template/game/Game.jsx";
import Result from "book-renderer/template/game/Result.jsx";

import {data as FrontCoverData} from "data/story/front-cover/index.js";
import {data as PageZeroData} from "data/story/page-zero/index.js";
import {data as PageOneData} from "data/story/page-one/index.js";
import {data as PageTwoData} from "data/story/page-two/index.js";
import {data as PageFourData} from "data/story/page-four/index.js";
import {data as PageFiveData} from "data/story/page-five/index.js";
import {data as PageSevenData} from "data/story/page-seven/index.js";
import {data as PageEightData} from "data/story/page-eight/index.js";
import {data as PageElevenData} from "data/story/page-eleven/index.js";
import {data as BackCoverData} from "data/story/back-cover/index.js";
import {data as BreakUpData} from "data/game/break-up/index.js";
import {data as CountingData} from "data/game/counting/index.js";
import {data as PuzzlingData} from "data/game/puzzling/index.js";
import {data as ResultData} from "data/game/result/index.js";

const PageTemplate = {
    Story: Story,
    Game: Game,
    Result: Result,
};

export const pages = [
    {
        name: 'FrontPage',
        template: PageTemplate.Story,
        backgroundColor: '#000000',
        // navigator: {},
        data: FrontCoverData
    }, 
    {
        name: 'PageZero',
        template: PageTemplate.Story,
        backgroundColor: '#000000',
        data: PageZeroData
    }, 
    {
        name: 'PageOne',
        template: PageTemplate.Story,
        backgroundColor: '#000000',
        data: PageOneData
    }, 
    {
        name: 'PageTwo',
        template: PageTemplate.Story,
        backgroundColor: '#000000',
        data: PageTwoData
    }, 
    {
        name: 'PageThree',
        template: PageTemplate.Game,
        backgroundColor: '#000000',
        data: BreakUpData
    },
    {
        name: 'PageFour',
        template: PageTemplate.Story,
        backgroundColor: '#000000',
        data: PageFourData
    }, 
    {
        name: 'PageFive',
        template: PageTemplate.Story,
        backgroundColor: '#000000',
        data: PageFiveData
    }, 
    {
        name: 'PageSix',
        template: PageTemplate.Game,
        backgroundColor: '#000000',
        data: CountingData
    }, 
    {
        name: 'PageSeven',
        template: PageTemplate.Story,
        backgroundColor: '#000000',
        data: PageSevenData
    }, 
    {
        name: 'PageEight',
        template: PageTemplate.Story,
        backgroundColor: '#000000',
        data: PageEightData
    }, 
    {
        name: 'PageNine',
        template: PageTemplate.Game,
        backgroundColor: '#000000',
        data: PuzzlingData
    }, 
    {
        name: 'PageTen',
        template: PageTemplate.Result,
        backgroundColor: '#000000',
        data: ResultData
    }, 
    {
        name: 'PageEleven',
        template: PageTemplate.Story,
        backgroundColor: '#000000',
        data: PageElevenData
    }, 
    {
        name: 'BackPage',
        template: PageTemplate.Story,
        backgroundColor: '#000000',
        data: BackCoverData
    },
];

// export const pages = [{
//     name: 'FrontPage',
//     type: PageType.Story,
//     text: '《魚缸米米宇宙無敵》',
//     assets: []
// }, {
//     name: 'PageOne',
//     type: PageType.Story,
//     text: '頭戴魚缸的米米，住在太空好久了，四處拯救宇宙需要幫助的動物們。',
//     assets: [{
//         url: '',
//         width: '',
//         height: '',
//         x: '',
//         y: '',
//         animation: '',
//         clickEvent: null
//     }]
// }, {
//     name: 'PageTwo',
//     type: PageType.Story,
//     text: '米米最常做的事，就是把被宇宙寒冰凍結的食物，喀拉喀垃地敲開來。',
// }, {
//     name: 'PageThree',
//     type: PageType.Game,
//     text: '關卡ㄧ',
//     gameComponent: BreakUp
// }, {
//     name: 'PageFour',
//     type: PageType.Story,
//     text: '米米忙著分送食物，再匆匆離開。『我是宇宙中唯一的英雄！』米米想。',
// }, {
//     name: 'PageFive',
//     type: PageType.Story,
//     text: '魚缸越來越霧，路開始看不清楚，米米跌落數字黑洞，迷糊中，他看見光。',
// }, {
//     name: 'PageSix',
//     type: PageType.Game,
//     text: '關卡二',
//     gameComponent: Counting
// }, {
//     name: 'PageSeven',
//     type: PageType.Story,
//     text: '魚缸破了，米米露出蓬鬆的大頭。睜開眼睛，好多張臉在眼前。',
// }, {
//     name: 'PageEight',
//     type: PageType.Story,
//     text: '米米想起一些沒戴魚缸前的記憶，大家陪著米米，就只是陪著。',
// }, {
//     name: 'PageNine',
//     type: PageType.Game,
//     text: '關卡三',
//     gameComponent: Puzzling
// }, {
//     name: 'PageTen',
//     type: PageType.Story,
//     text: '『我們都是彼此的英雄。』米米想。',
// }, {
//     name: 'BackPage',
//     type: PageType.Story,
//     text: '123',
// }];