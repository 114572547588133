import React from "react";
import ButtonHome from "./assets/button-home.png";
import ButtonHomeGlowing from "./assets/button-home-glowing.png";
import ButtonFB from "./assets/button-fb.png";
import ButtonFBGlowing from "./assets/button-fb-glowing.png";
import SoundButton from "./../../../../sound-button/SoundButton.jsx"

import styles from "./Header.module.scss";

const Header = props => {
    return (
        <div className={styles.Wrapper}>
            <SoundButton 
                className={styles.Item} 
                onClick={() => window.open('https://www.eden.org.tw/', '_blank')}
            >
                <>  
                    <img 
                        src={ButtonHome} 
                        // style={{ height: "70%" }} 
                    />
                    <img 
                        className={styles.Glowing} 
                        src={ButtonHomeGlowing} 
                    />
                    <div className={styles.TipWrapper}><p>伊甸官網</p></div>
                </>
            </SoundButton>
            <SoundButton 
                className={styles.Item} 
                onClick={() => window.open('https://www.facebook.com/edenfan/', '_blank')}
            >
                <>
                    <img 
                        src={ButtonFB} 
                        // style={{ height: "70%" }} 
                    />
                    <img 
                        className={styles.Glowing} 
                        src={ButtonFBGlowing} 
                    />
                    <div className={styles.TipWrapper}><p>伊甸 FB</p></div>
                </>
            </SoundButton>
        </div>
    );
}

export default Header;