import Paulser_0 from "./assets/orange/paulser.png";
import Clock_0 from "./assets/orange/clock.png";
import Numbers_0 from "./assets/orange/numbers.png";
import Progress_0 from "./assets/orange/progress.png";
import ProgressBase_0 from "./assets/orange/progress-base.png";
import Paulser_1 from "./assets/aqua-blue/paulser.png";
import Clock_1 from "./assets/aqua-blue/clock.png";
import Numbers_1 from "./assets/aqua-blue/numbers.png";
import Progress_1 from "./assets/aqua-blue/progress.png";
import ProgressBase_1 from "./assets/aqua-blue/progress-base.png";
import Paulser_2 from "./assets/yellow/paulser.png";
import Clock_2 from "./assets/yellow/clock.png";
import Numbers_2 from "./assets/yellow/numbers.png";
import Progress_2 from "./assets/yellow/progress.png";
import ProgressBase_2 from "./assets/yellow/progress-base.png";

export const data = {
    'Orange': {
        clock: Clock_0,
        numbers: Numbers_0,
        progress: Progress_0,
        progressBase: ProgressBase_0,
        paulser: Paulser_0,
    },
    'AquaBlue': {
        clock: Clock_1,
        numbers: Numbers_1,
        progress: Progress_1,
        progressBase: ProgressBase_1,
        paulser: Paulser_1,
    },
    'Yellow': {
        clock: Clock_2,
        numbers: Numbers_2,
        progress: Progress_2,
        progressBase: ProgressBase_2,
        paulser: Paulser_2,
    }
};