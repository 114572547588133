import React from 'react';
import { LazyImage } from "../../../book-renderer/LazyImage.jsx";
import FinishedGlowing from "./assets/game/finished-glowing.png";
import FinishedHandDrawLines from "./assets/game/finished-hand-draw-lines.png";
import FinishedLittleBG from "./assets/game/finished-little-BG.png";
import FinishedText from "./assets/game/finished-text.png";
import Finished from "./assets/game/finished.jpg";
import styles from "./SuccessAnimation.module.scss";
import styles_Basic from "../../../book-renderer/ScenceBasicElement.module.scss";

import useSound from "use-sound";
import finishedSuccessMp3 from "./assets/game/finished-success.mp3";
import { useEffect } from 'react';

export const SuccessAnimation = props => {
    const [play] = useSound(finishedSuccessMp3);

    useEffect(() => {
        if(props.isActive) play();
    }, [props.isActive])

    return (
        <div 
            className={
                `${styles.FinishedWrapper}` 
                + ` ${props.isActive ? styles.Active : ''}` 
                + ` ${styles_Basic.AssetWrapper}`
            } 
        >
            <LazyImage
                loadLazyImage={props.loadLazyImage}
                src={Finished}
                attributes={{
                    className: styles.Finished,
                    draggable: false,
                    alt: ""
                }}
            />
            <LazyImage
                loadLazyImage={props.loadLazyImage}
                src={FinishedGlowing}
                attributes={{
                    className: styles.FinishedGlowing,
                    draggable: false,
                    alt: ""
                }}
            />
            <LazyImage
                loadLazyImage={props.loadLazyImage}
                src={FinishedHandDrawLines}
                attributes={{
                    className: styles.FinishedHandDrawLines,
                    draggable: false,
                    alt: ""
                }}
            />
            <LazyImage
                loadLazyImage={props.loadLazyImage}
                src={FinishedLittleBG}
                attributes={{
                    className: styles.FinishedLittleBG,
                    draggable: false,
                    alt: ""
                }}
            />
            <LazyImage
                loadLazyImage={props.loadLazyImage}
                src={FinishedText}
                attributes={{
                    className: styles.FinishedText,
                    draggable: false,
                    alt: ""
                }}
            />
        </div>
    )
}