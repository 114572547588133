import React, { Component } from 'react';
import { LazyImage } from "./../../../book-renderer/LazyImage.jsx";
import { Targets } from "./Targets.jsx";
import GameBar from "./../game-bar/GameBar.jsx";
import styles from "./Counting.module.scss";
import { data } from "./data/game.js";
import styles_Basic from "../../../book-renderer/ScenceBasicElement.module.scss";

const gameTime = 50;
const successPauseTime = 2;
const failurePauseTime = 2;
const targetNumber = data.targets.length;

const initData = {
    countingTime: 0,
    countingNumber: -1,
    freezeAllElement: false,
}

class Counting extends Component {
    static displayName = 'Counting';
    
    constructor(props) {
        super(props);
        this.state = {
            isPausing: props.isPausing,
            countingTime: 0,
            countingNumber: -1,
            freezeAllElement: false,
        }
        this.mainInterval = null;
    }

    componentDidMount() {
        this.initData();
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.isPausing !== nextProps.isPausing) {
            this.setState({ isPausing: nextProps.isPausing });
        }

        if(this.props.uuid !== nextProps.uuid) {
            this.initData();
        }
    }

    initData = () => {
        this.setState((prevState) => ({ 
            ...prevState,
            ...initData 
        }), () => {
            clearInterval(this.mainInterval);
            this.mainInterval = setInterval(this.handleTimer, 1000)
        });
    }

    handleTimer = () => {
        if(!this.state.isPausing) {
            this.setState({ countingTime: this.state.countingTime+1 }, () => {
                const currentTime = this.state.countingTime;
                if(gameTime - currentTime === 0) {
                    this.setState({ freezeAllElement: true });
                    clearInterval(this.mainInterval);
                    setTimeout(() => {
                        this.props.loseGame();
                    }, failurePauseTime*1000);
                }
            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.mainInterval);
    }

    handleTargetClick = (index) => {
        if(index === this.state.countingNumber+1) {
            this.setState({
                countingNumber: index
            }, () => {
                if(this.state.countingNumber === targetNumber-1) {
                    this.setState({ freezeAllElement: true });
                    clearInterval(this.mainInterval);
                    setTimeout(() => {
                        this.props.winGame(1);
                    }, successPauseTime*1000);
                }
            });
        } else {
            this.setState({ countingNumber: -1 });
        }
    }

    render() {
        return (
            <div className={
                `${styles_Basic.BackgroundWrapper}`
                + ` ${this.state.freezeAllElement ? styles.Freeze : '' }`
            }>                
                <img className={styles_Basic.BackgroundImg} src={data.backgroundUrl} />

                {data.assets.map((asset, index) => 
                    <div 
                        key={index}
                        className={styles.AssetWrapper}
                        style={{
                            width: asset.width,
                            left: asset.x,
                            top: asset.y,
                        }}
                    >
                        <LazyImage
                            loadLazyImage={this.props.loadLazyImage}
                            src={asset.url}
                            attributes={{
                                draggable: false,
                                alt: ""
                            }}
                        />
                    </div>
                    
                )}

                <GameBar 
                    colorType="AquaBlue"
                    gameTime={gameTime}
                    countingTime={this.state.countingTime}
                    isPausing={this.state.isPausing}
                    isFreezingAllElement={this.state.freezeAllElement}
                    resumeGame={this.props.resumeGame}
                    pauseGame={this.props.pauseGame}
                    loadLazyImage={this.props.loadLazyImage}
                />
                
                <Targets 
                    loadLazyImage={this.props.loadLazyImage}
                    targetsData={data.targets}
                    countingNumber={this.state.countingNumber}
                    countingTime={this.state.countingTime}
                    isPausing={this.state.isPausing}
                    handleTargetClick={this.handleTargetClick}  
                />
            </div>
        );
    }
}

export default Counting;