import frontCoverBG from "./assets/BG.jpg";
// import frontCoverBG from "./assets/tmp.jpg";

import AnimalLion from "./assets/animal-lion.png";
import AnimalDog from "./assets/animal-dog.png";
import AnimalFye from "./assets/animal-fye.png";
import AnimalGiraffe from "./assets/animal-giraffe.png";
import AnimalElephant from "./assets/animal-elephant.png";
import AnimalPenguin from "./assets/animal-penguin.png";
import EdenLogo from "./assets/eden-logo.png";
import Title from "./assets/title.png";
import Text from "./assets/text.png";
import frontCoverStars from "./assets/stars.png";
import Arrow from "./assets/arrow.png";

import Header from "../../../book-renderer/template/story/headers/front-cover/Header.jsx";
import animations from "./../../assets/Animation.module.scss";

export const data = {
    barComponent: Header,
    mainText: '你不用一個人努力！\n我們都是發著光，\n能為彼此帶來幸福的小星星。',
    mainTextDelay: 800,
    backgroundUrl: frontCoverBG,
    assets: [
        {
            url: AnimalLion,
            width: '13.2%',
            x: '26.3%',
            y: '35.4%',
        },
        {
            url: AnimalDog,
            width: '11%',
            x: '16.9%',
            y: '48.1%',
        },
        {
            url: AnimalFye,
            width: '19%',
            x: '29.7%',
            y: '52.6%',
            animeProperties: {
                translateY: ['3%', '-3%'],
                duration: 3000,
                direction: 'alternate',
                loop: true,
                easing: 'easeInOutSine',
                translateZ: 0,
            },
        },
        {
            url: AnimalGiraffe,
            width: '22.7%',
            x: '67.1%',
            y: '41.6%',
        },
        {
            url: AnimalElephant,
            width: '25.9%',
            x: '34.3%',
            y: '77.6%',
        },
        {
            url: AnimalPenguin,
            width: '35.8%',
            x: '63.2%',
            y: '67.5%',
        },
        {
            url: EdenLogo,
            width: '3.8%',
            x: '49.5%',
            y: '30.8%',
        },
        {
            url: Title,
            width: '19%',
            x: '48.8%',
            y: '47.8%',
            // animeProperties: {
            //     opacity: [0, 1],
            //     delay: 800,
            //     duration: 800,
            //     easing: 'easeInOutSine'
            // },
        },
        {
            url: Text,
            width: '37.7%',
            x: '51.25%',
            y: '66.2%',
            hideOnMobile: true,
            animeProperties: {
                opacity: [0, 1],
                delay: 800,
                duration: 800,
                easing: 'easeInOutSine'
            },
        },
        {
            url: frontCoverStars,
            width: '86.2%',
            x: '43.1%',
            y: '65%',
        }
    ],
    buttons: [
        {
            url: Arrow,
            width: '2.6%',
            x: '50%',
            y: '80.4%',
            animation: animations.ArrowPonting,
            clickEvent: () => window.fullpage_api.moveSectionDown()
        },
    ]
};