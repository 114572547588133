import EndingBG from "./../assets/modal/ending-BG.jpg";
// import EndingBG from "./../assets/modal/tmp-success.jpg";
// import EndingBG from "./../assets/modal/tmp-failure.jpg";
import Wrapper from "./../assets/modal/wrapper.png";
import Wrapper_2 from "./../assets/modal/wrapper-2.png";
import WrapperTitle from "./../assets/modal/wrapper-title.png";
import WrapperText from "./../assets/modal/wrapper-text.png";
import WrapperButtonStart from "./../assets/modal/wrapper-button-start.png";
import WrapperButtonContinue from "./../assets/modal/wrapper-button-continue.png";
import WrapperButtonGoNextPage from "./../assets/modal/wrapper-button-go-next-page.png";
import WrapperButtonRestart from "./../assets/modal/wrapper-button-restart.png";

import ButtonRestart from "./../assets/modal/button-restart.png";
import ButtonGoNextPage from "./../assets/modal/button-go-next-page.png";

import TextSuccess from "./../assets/modal/text-success.png";
import SuccessFruitOrange from "./../assets/modal/success-fruit-orange.png";
import SuccessFruitPear from "./../assets/modal/success-fruit-pear.png";
import SuccessFruitCherry from "./../assets/modal/success-fruit-cherry.png";

import TextFailure from "./../assets/modal/text-failure.png";

import EndingAnimalPenguin from "./../assets/modal/ending-animal-penguin.png";
import EndingAnimalLion from "./../assets/modal/ending-animal-lion.png";
import EndingAnimalGiraffe from "./../assets/modal/ending-animal-giraffe.png";

const FunctionType = {
    CloseModal: "CloseMoal",
    RestartGame: "RestartGame",
    BackToStory: "BackToStory",
};

export const data = {
    Landing: {
        backgroundUrl: null,
        buttons: [
            {
                functionType: FunctionType.CloseModal,
                url: WrapperButtonStart,
                width: '16.5%',
                x: '49.3%',
                y: '60.35%',
            }
        ],
        assets: [
            {
                url: Wrapper,
                width: '45.3%',
                x: '49.8%',
                y: '55.1%',
            },
            {
                url: WrapperTitle,
                width: '32.8%',
                x: '50%',
                y: '38%',
            },
            {
                url: WrapperText,
                width: '35.3%',
                x: '50.3%',
                y: '50.5%',
            }
        ]
    },
    Pausing: {
        backgroundUrl: null,
        buttons: [
            {
                functionType: FunctionType.CloseModal,
                url: WrapperButtonContinue,
                width: '15.6%',
                x: '49.9%',
                y: '56.3%',
            },
            {
                functionType: FunctionType.RestartGame,
                url: WrapperButtonRestart,
                width: '16.2%',
                x: '41.5%',
                y: '63.5%',
            },
            {
                functionType: FunctionType.BackToStory,
                url: WrapperButtonGoNextPage,
                width: '16.1%',
                x: '59.3%',
                y: '63.6%',
            }
        ],
        assets: [
            {
                url: Wrapper_2,
                width: '48.8%',
                x: '50.6%',
                y: '54%',
            },
            {
                url: WrapperTitle,
                width: '32.8%',
                x: '50%',
                y: '35%',
            },
            {
                url: WrapperText,
                width: '35.1%',
                x: '50.6%',
                y: '46.2%',
            }
        ]
    },
    Failure: {
        backgroundUrl: EndingBG,
        buttons: [
            {
                functionType: FunctionType.RestartGame,
                url: ButtonRestart,
                width: '13.9%',
                x: '49.4%',
                y: '67.4%',
            },
            {
                functionType: FunctionType.BackToStory,
                url: ButtonGoNextPage,
                width: '13.7%',
                x: '49.7%',
                y: '76.2%',
            }
        ],
        assets: [
            {
                url: TextFailure,
                width: '24.2%',
                x: '50.3%',
                y: '41.1%',
            },
            {
                url: EndingAnimalPenguin,
                width: '16.5%',
                x: '68.9%',
                y: '31.3%',
            },
            {
                url: EndingAnimalGiraffe,
                width: '19.4%',
                x: '25.6%',
                y: '42%',
            },
            {
                url: EndingAnimalLion,
                width: '14.5%',
                x: '70.2%',
                y: '66.7%',
            },
        ]
    },
    Success: {
        backgroundUrl: EndingBG,
        buttons: [
            {
                functionType: FunctionType.RestartGame,
                url: ButtonRestart,
                width: '13.9%',
                x: '49.4%',
                y: '67.4%',
            },
            {
                functionType: FunctionType.BackToStory,
                url: ButtonGoNextPage,
                width: '13.7%',
                x: '49.7%',
                y: '76.2%',
            }
        ],
        assets: [
            {
                url: TextSuccess,
                width: '23.4%',
                x: '48.1%',
                y: '41%',
                // animation: animations.SpaceFloating_1,
            },
            {
                url: EndingAnimalPenguin,
                width: '16.5%',
                x: '68.9%',
                y: '31.3%',
            },
            {
                url: EndingAnimalGiraffe,
                width: '19.4%',
                x: '25.6%',
                y: '42%',
            },
            {
                url: SuccessFruitOrange,
                width: '5.3%',
                x: '63.6%',
                y: '50%',
            },
            {
                url: EndingAnimalLion,
                width: '14.5%',
                x: '70.2%',
                y: '66.7%',
            },
            {
                url: SuccessFruitCherry,
                width: '8.3%',
                x: '62.4%',
                y: '38%',
            },
            {
                url: SuccessFruitPear,
                width: '3.8%',
                x: '33.6%',
                y: '38.7%',
            },
        ]
    }
};