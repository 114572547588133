import React, { useEffect } from 'react';
import styles from './App.module.scss';
import StoryBook from "book-renderer/StoryBook.jsx";

function App() {
  
  useEffect(() => {
    window.addEventListener('load', handleLoad);
    return () => window.removeEventListener('load', handleLoad) 
  }, []);

  const handleLoad = () => {
    let loader = document.querySelector('.loader');
    loader.classList.add('loader--hide');
  }

  return (
    <div className={styles.App}>
      <StoryBook />
    </div>
  );
}

export default App;
