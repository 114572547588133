import BG from "./assets/BG.jpg";
// import BG from "./assets/tmp.jpg";

import LionEyes from "./assets/lion-eyes.png";
import AnimalPenguin from "./assets/animal-penguin.png";
import PenguinEyes from "./assets/penguin-eyes.png";
import Text from "./assets/text.png";

import FishTank from "./assets/fish-tank.png";
import FishSparkling from "./assets/fish-tank-sparkling.png";
import { data as marketingData } from "./../../marketing/index.js";

import Wipes from "./../../assets/wipes.png";
import animations from "./../../assets/Animation.module.scss";

export const data = {
    barComponent: null,
    mainText: '動物們一起，\n將起霧的魚缸拿下來。',
    mainTextDelay: 2800,
    backgroundUrl: BG,
    assets: [
        {
            url: LionEyes,
            width: '9.6%',
            x: '26.4%',
            y: '59.05%',
            rotate: '9deg',
            animeProperties: {
                transformOrigin: 'bottom',
                scaleY: [
                    { value: [1, 0.1], duration: 60, delay: 3200, direction: 'alternate' },
                    { value: [1, 0.1], duration: 60, delay: 300, direction: 'alternate' }
                ],
                loop: true,
                easing: 'easeOutSine'
            },
        },
        {
            url: AnimalPenguin,
            width: '33.6%',
            x: '45.6%',
            y: '76%',
            // zIndex: 3,
        },
        {
            url: PenguinEyes,
            width: '13.4%',
            x: '49.6%',
            y: '66.8%',
            rotate: '25.5deg',
            // zIndex: 3,
            animeProperties: {
                transformOrigin: 'bottom',
                scaleY: [
                    { value: [1, 0.1], duration: 60, delay: 2400, },
                    { value: [0.1, 1], duration: 60, endDelay: 1400, },
                ],
                loop: true,
                easing: 'easeOutSine'
            },
        },
        {
            url: Text,
            width: '38.2%',
            x: '48.1%',
            y: '26.2%',
            zIndex: 3,
            hideOnMobile: true,
            animeProperties: {
                opacity: [0, 1],
                delay: 2800,
                duration: 800,
                easing: 'easeInOutSine'
            },
        },
    ],
    marketings: [
        {
            id: 3,
            url: FishTank,
            width: '29%',
            x: '49.7%',
            y: '45%',
            content: marketingData[3],
            attaches: [
                {
                    url: FishSparkling,
                    width: '95%',
                    x: '50%',
                    y: '48%',
                    zIndex: -1,
                    animation: animations.Sparkling,
                },
                {
                    url: Wipes,
                    width: '103%',
                    x: '51%',
                    y: '54%',
                    animation: animations.Wipping,
                },
            ]
        },
    ],
};